import React from 'react';
import PropTypes from 'prop-types';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function GraphExpanderButton({
  isExpanded, onButtonClick, smallerText, biggerText, isDisabled = false,
}) {
  const classes = useStyles();

  return (
    <Button className={classes.expandButton}
            startIcon={isExpanded ? <UpIcon /> : <DownIcon />}
            onClick={() => onButtonClick(!isExpanded)}
            disableElevation
            size="small"
            aria-label="Minimize graph"
            disabled={isDisabled}>
      {isExpanded ? smallerText : biggerText}
    </Button>
  );
}

const useStyles = makeStyles(() => ({
  expandButton: {
    alignSelf: 'flex-start',
    flexShrink: 0,
    '& .MuiButton-startIcon': {
      marginRight: '2px',
    },
  },
}));

GraphExpanderButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  smallerText: PropTypes.object.isRequired, // translate
  biggerText: PropTypes.object.isRequired, // translate
  isDisabled: PropTypes.bool,
};

export default GraphExpanderButton;
