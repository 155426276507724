import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Translate } from 'react-localize-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

export default function MultiToggleButton({
  onChange,
  defaultInterval,
  buttons,
  isDisabled = false,
  className = '',
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isMDScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLGScreen = useMediaQuery(theme.breakpoints.only('lg'));

  const [selectedInterval, setSelectedInterval] = useState(defaultInterval);

  function onButtonPressed(_, newValue) {
    if (newValue) {
      setSelectedInterval(newValue);
      onChange(newValue);
    }
  }

  return (
    <ToggleButtonGroup value={selectedInterval}
                       onChange={onButtonPressed}
                       exclusive
                       variant="contained"
                       size="small"
                       className={clsx(classes.intervalButtonsContainer, className)}>
      {buttons.map(b => (
        <ToggleButton value={b.value}
                      classes={{ selected: classes.selectedToggleButton }}
                      className={classes.toggleButton}
                      key={b.value}
                      disabled={isDisabled}>
          <Translate id={(isXSScreen || isMDScreen || isLGScreen) ? b.shortId : b.longId} />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

const useStyles = makeStyles(theme => ({
  selectedToggleButton: {
    color: 'white !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  toggleButton: {
    color: '#444',
    padding: '0.25rem 0.75rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  },
  intervalButtonsContainer: {
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
  },
}));

MultiToggleButton.propTypes = {
  defaultInterval: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  buttons: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};
