/* eslint-disable no-underscore-dangle */
import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Map, TileLayer, FeatureGroup } from 'react-leaflet';

import { useSelector, useDispatch } from 'react-redux';
import {
  greenMarker, greenMarkerBig, orangeMarker, orangeMarkerBig, redMarker, redMarkerBig,
} from '../../services/map-marker-helper';

import { clearUrl, addSensorIdToUrl } from '../../services/url-helper';
import { sensorStatuses } from '../../services/data-processing-helper';

const startPosition = [63.392, 10.423];

function createMarkerAndAddToMap(markerInfo, isHightlighted) {
  const newMarker = L.marker(
    [markerInfo.lat, markerInfo.lon],
    { icon: getMarkerElement(markerInfo.status.status, isHightlighted) },
  );
  newMarker.metadata = { isHightlighted, ...markerInfo };

  return newMarker;
}

function getMarkerElement(sensorStatus, isHighlighted) {
  if (sensorStatus === sensorStatuses.healthy) {
    return isHighlighted ? greenMarkerBig : greenMarker;
  }

  if (sensorStatus === sensorStatuses.unstable) {
    return isHighlighted ? orangeMarkerBig : orangeMarker;
  }

  return isHighlighted ? redMarkerBig : redMarker;
}

export default function SensorMap({ isMapVisible }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const mapRef = useRef();
  const markerGroupRef = useRef();

  const { sensorID: sensorIdFromQuery } = useParams();
  const { payload: allSensors } = useSelector(state => state.sensors);
  const selectedSensor = useSelector(state => state.selectedSensor);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [areSensorsAdded, setAreSensorsAdded] = useState(false);


  const toggleMarkerHighlighted = useCallback((marker, shouldHighlight) => {
    markerGroupRef.current.leafletElement.removeLayer(marker._leaflet_id);

    const newMarker = createMarkerAndAddToMap(marker.metadata, shouldHighlight);
    newMarker.on('click', markerObject => dispatch({
      type: 'SET_SELECTED_SENSOR',
      value: markerObject.sourceTarget.metadata,
    }));
    newMarker.addTo(markerGroupRef.current.leafletElement);
    newMarker.metadata.isHighlighted = shouldHighlight;
  }, [dispatch]);


  const removeSelectedMarkerIfExists = useCallback(() => {
    Object.values(markerGroupRef.current.leafletElement._layers).forEach((marker) => {
      if (marker.metadata.isHighlighted) {
        toggleMarkerHighlighted(marker, false);
      }
    });
  }, [toggleMarkerHighlighted]);


  const reCenterMap = useCallback(async delayMs => new Promise((resolve) => {
    setTimeout(() => {
      if (mapRef.current && currentMarker) {
        mapRef.current.leafletElement.invalidateSize();
        mapRef.current.leafletElement.panTo(currentMarker._latlng);
      }

      resolve();
    }, delayMs);
  }), [currentMarker]);


  const onSelectedSensorChanged = useCallback(async () => {
    addSensorIdToUrl(history, selectedSensor.deviceID);

    let selectedMarker;

    Object.values(markerGroupRef.current.leafletElement._layers)
      .forEach((marker) => {
        if (marker.metadata.deviceID === selectedSensor.deviceID) {
          selectedMarker = marker;
        }
        else if (marker.metadata.isHighlighted) {
          toggleMarkerHighlighted(marker, false);
        }
      });

    toggleMarkerHighlighted(selectedMarker, true);
    setCurrentMarker(selectedMarker);
    mapRef.current.leafletElement.panTo(selectedMarker._latlng);
  }, [selectedSensor, history, toggleMarkerHighlighted]);


  const waitForMapLoad = useCallback(async () => new Promise(async (resolve) => {
    const isMapLoaded = markerGroupRef.current && mapRef.current;
    if (isMapLoaded) {
      resolve();
    }
    else {
      await sleep(50);
      await waitForMapLoad();
      resolve();
    }
  }), []);


  useEffect(() => {
    if (allSensors.length > 0 && !areSensorsAdded) {
      addSensorsToMap();
      setAreSensorsAdded(true);
    }

    async function addSensorsToMap() {
      await waitForMapLoad(); // Sometimes Leaflet is a little slow to load
      const map = mapRef.current.leafletElement;
      const markerGroup = markerGroupRef.current.leafletElement;
      let markerToSelectFromUrl;

      allSensors.forEach((sensor) => {
        const marker = createMarkerAndAddToMap(sensor, false);

        marker.on('click', markerObject => dispatch({
          type: 'SET_SELECTED_SENSOR',
          value: markerObject.sourceTarget.metadata,
        }));

        marker.addTo(markerGroupRef.current.leafletElement);

        if (sensorIdFromQuery && marker.metadata.deviceID === sensorIdFromQuery) {
          markerToSelectFromUrl = marker;
        }
      });

      map.setView(new L.LatLng(63.378653, 10.3853961), 11);
      map.fitBounds(markerGroup.getBounds(), { padding: [5, 5] });

      if (markerToSelectFromUrl) {
        setTimeout(() => dispatch({
          type: 'SET_SELECTED_SENSOR',
          value: markerToSelectFromUrl.metadata,
        }));
      }
      else if (sensorIdFromQuery) {
        clearUrl(history);
      }
    }
  }, [allSensors, areSensorsAdded, dispatch, history, sensorIdFromQuery, waitForMapLoad]);


  useEffect(() => {
    if (!selectedSensor && allSensors.length > 0) {
      removeSelectedMarkerIfExists();
    }
    if (selectedSensor) {
      onSelectedSensorChanged();
    }
  }, [selectedSensor, allSensors, onSelectedSensorChanged, removeSelectedMarkerIfExists]);


  useEffect(() => {
    if (isMapVisible) {
      reCenterMap(0);
    }
  }, [isMapVisible, reCenterMap]);


  useEffect(() => {
    reCenterMap(5);
    reCenterMap(200);
  }, [currentMarker, reCenterMap]);

  return (
    <Map center={startPosition} ref={mapRef}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                 attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors" />
      <FeatureGroup ref={markerGroupRef} />
    </Map>
  );
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

SensorMap.propTypes = {
  isMapVisible: PropTypes.bool.isRequired,
};
