import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import {
  ComposedChart, Line, XAxis, YAxis, CartesianGrid,
  ResponsiveContainer, Tooltip, Bar, ReferenceLine,
} from 'recharts';
import ReloadIcon from '@material-ui/icons/Loop';
import { format } from 'date-fns';
import {
  Typography, Box, LinearProgress, Button,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import DotOutlineIcon from '@material-ui/icons/FiberManualRecordOutlined';
import clsx from 'clsx';

const dataKeys = ['precipitation', 'temperature', 'humidity'];
const dataColors = {
  precipitation: '#33ccffaa',
  temperature: '#222',
  humidity: '#9d9d9d',
};

const tooltipUnits = {
  precipitation: 'mm',
  temperature: '°C',
  humidity: '%',
};

export default function WeatherGraph({
  loadDataFunction,
  commonGraphClasses,
  activeLanguage: { code: languageCode },
}) {
  const {
    fetching: isFetchingData,
    fetched: hasFetchedData,
    error: isFetchError,
    payload: weatherData,
  } = useSelector(state => state.sensorWeatherData);

  const selectedSensor = useSelector(state => state.selectedSensor);

  const [hiddenDataTypes, setHiddenDataTypes] = useState(['humidity']);
  const [isAnyPrecipitation, setIsAnyPrecipitation] = useState(false);
  const [shouldShowZeroDegreeLine, setShouldShowZeroDegreeLine] = useState(false);

  useEffect(() => {
    let isPrecipitation = false;
    let isNegativeTemperatures = false;
    let isPositiveTemperatures = false;
    if (weatherData && weatherData.data && weatherData.data.length > 0) {
      weatherData.data.forEach((datapoint) => {
        if (datapoint.precipitation > 0) {
          isPrecipitation = true;
        }
        if (datapoint.temperature < 0) {
          isNegativeTemperatures = true;
        }
        if (datapoint.temperature > 0) {
          isPositiveTemperatures = true;
        }
      });
    }
    setIsAnyPrecipitation(isPrecipitation);
    setShouldShowZeroDegreeLine(isNegativeTemperatures && isPositiveTemperatures);
  }, [weatherData]);

  function toggleDataTypeVisibility(dataType) {
    if (hiddenDataTypes.includes(dataType)) {
      setHiddenDataTypes(hiddenDataTypes.filter(dt => dt !== dataType));
    }
    else {
      setHiddenDataTypes([...hiddenDataTypes, dataType]);
    }
  }

  function formatTooltipLabel(timeString) {
    const date = new Date(timeString);
    return format(date, 'EEE dd. MMM yyyy, HH:mm');
  }

  function formatTooltipValue(value, labelName) {
    if (labelName === 'temperature') {
      return [
        `${new Intl.NumberFormat(languageCode).format(value)} ${tooltipUnits[labelName]}`,
        <Translate id="domain.temperature" />,
      ];
    }

    return [
      `${new Intl.NumberFormat(languageCode).format(value)} ${tooltipUnits[labelName]}`,
      <Translate id="domain.humidity" />,
    ];
  }

  function formatAxisDateTooltip(timeString) {
    const date = new Date(timeString);
    const firstTimestamp = weatherData.data[0].time;
    const lastTimestamp = weatherData.data[weatherData.data.length - 1].time;
    const dateRange = new Date(lastTimestamp) - new Date(firstTimestamp);
    if (dateRange > 86400000 * 4) {
      return format(date, 'dd.MM');
    }
    return format(date, 'kk:mm');
  }

  function mainGraphContent() {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" width="100%" padding="0 0.3rem" className={commonGraphClasses.graphAndAverageContainer}>
          <ResponsiveContainer height={140}>
            <ComposedChart data={weatherData.data}>
              <CartesianGrid strokeDasharray="3,3" />
              <XAxis dataKey="time"
                     tickFormatter={timeStr => formatAxisDateTooltip(timeStr)}
                     tickLine={false} />
              <YAxis yAxisId="temperatureAxis"
                     allowDecimals={false}
                     width={30} />
              <YAxis yAxisId="precipitationAxis"
                     mirror
                     orientation="right"
                     hide={hiddenDataTypes.includes('precipitation') || !isAnyPrecipitation} />
              <YAxis yAxisId="humidityAxis"
                     hide />

              <Tooltip labelFormatter={formatTooltipLabel}
                       formatter={formatTooltipValue}
                       separator=": " />

              {shouldShowZeroDegreeLine && (
                <ReferenceLine y={0}
                               yAxisId="temperatureAxis"
                               stroke="#194863"
                               strokeDasharray="5 5"
                               strokeWidth={1.5} />
              )}

              {
                dataKeys.filter(dataType => !hiddenDataTypes.includes(dataType))
                  .map((dataType) => {
                    if (dataType === 'precipitation') {
                      return (
                        <Bar yAxisId="precipitationAxis"
                             dataKey={dataType}
                             key={dataType}
                             fill={dataColors.precipitation} />
                      );
                    }
                    return (
                      <Line type="monotone"
                            yAxisId={`${dataType}Axis`}
                            dot={false}
                            dataKey={dataType}
                            key={dataType}
                            animationDuration={500}
                            stroke={dataColors[dataType]}
                            strokeWidth={3} />
                    );
                  })
              }
            </ComposedChart>
          </ResponsiveContainer>
        </Box>

        <GraphLegend classes={commonGraphClasses}
                     commonGraphClasses={commonGraphClasses}
                     toggleDataTypeVisibility={toggleDataTypeVisibility}
                     hiddenDataTypes={hiddenDataTypes} />
      </Box>
    );
  }

  function noDataContent() {
    return (
      <Box width="100%" display="flex" flexDirection="column" padding="1rem" justifyContent="center">
        <Typography style={{ textAlign: 'center' }}>
          <Translate id="main.noDataInSelectedInterval" />
        </Typography>
      </Box>
    );
  }

  function errorContent() {
    return (
      <Box width="100%" display="flex" flexDirection="column" padding="1rem" justifyContent="center">
        <Typography style={{ textAlign: 'center' }}>
          <Translate id="main.errorFetchingSensorData" />
        </Typography>
        <Button onClick={() => loadDataFunction()}
                variant="outlined"
                color="primary"
                startIcon={<ReloadIcon />}
                className={commonGraphClasses.retryButton}>
          <Translate id="main.retry" />
        </Button>
      </Box>
    );
  }

  function loadingContent() {
    return (
      <Box width="100%"
           display="flex"
           flexDirection="column"
           justifyContent="center"
           className={commonGraphClasses.loadingBox}>
        <LinearProgress />
      </Box>
    );
  }

  function getContent() {
    if (isFetchError) {
      return errorContent();
    }
    if (isFetchingData || !hasFetchedData || !selectedSensor) {
      return loadingContent();
    }
    if (!isFetchError && !isFetchingData) {
      if (weatherData.data.length) {
        return mainGraphContent();
      }

      return noDataContent();
    }

    return loadingContent();
  }

  return (
    <Box width="100%" marginTop="0.3rem">
      {getContent()}
    </Box>
  );
}

WeatherGraph.propTypes = {
  loadDataFunction: PropTypes.func.isRequired,
  commonGraphClasses: PropTypes.objectOf(PropTypes.string).isRequired,
  activeLanguage: PropTypes.object,
};

WeatherGraph.defaultProps = {
  activeLanguage: {},
};

const GraphLegend = ({
  classes, toggleDataTypeVisibility, hiddenDataTypes, commonGraphClasses,
}) => (
  <Box className={clsx(classes.legendContainer, classes.clickableLegendContainer)}>
    {Object.entries(dataColors).map(([dataType, dataColor]) => {
        const isHidden = hiddenDataTypes.includes(dataType);

        return (
          <Box display="flex"
               flexDirection="row"
               key={dataType}
               onClick={() => toggleDataTypeVisibility(dataType)}
               className={commonGraphClasses.clickableLegend}>
            {isHidden ? (
              <DotOutlineIcon fontSize="small" style={{ color: dataColor }} />
            ) : (
              <DotIcon fontSize="small" style={{ color: dataColor }} />
            )}
            <Typography variant="body2">
              <Translate id={`domain.${dataType}`} />
            </Typography>
          </Box>
        );
      })}
  </Box>
);

GraphLegend.propTypes = {
  classes: PropTypes.any.isRequired,
  toggleDataTypeVisibility: PropTypes.func.isRequired,
  hiddenDataTypes: PropTypes.array.isRequired,
  commonGraphClasses: PropTypes.object.isRequired,
};
