import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Translate, getTranslate } from 'react-localize-redux';

import {
  Box, Typography, Button, Paper, Hidden, Grid,
  CircularProgress, Tabs, Tab,
} from '@material-ui/core';

import DotIcon from '@material-ui/icons/FiberManualRecord';
import ReloadIcon from '@material-ui/icons/Loop';
import MapIcon from '@material-ui/icons/MapOutlined';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import InfoIcon from '@material-ui/icons/Info';

import { doFetch } from '@trdk/style/Redux/fetch.actions';
import Dust from '../components/dust/dust';
import Gases from '../components/gas/gases';
import Weather from '../components/weather/weather';
import SensorMap from '../components/sensors/sensor-map';
import SensorList from '../components/sensors/sensor-list';
import { fetchAllSensors as fetchSensorsFromApi } from '../services/sensor-service';
import { sensorStatuses } from '../services/data-processing-helper';

function Content({ translate }) {
  const [selectedTab, setSelectedTab] = useState('map');
  const [shouldDisableListTab, setShouldDisableListTab] = useState(false);

  const classes = useStyles();
  const commonGraphClasses = useGraphStyles();
  const theme = useTheme();

  const {
    fetching: isFetchingSensors,
    fetched: hasFetchedSensors,
    error: isErrorFetchingSensors,
  } = useSelector(state => state.sensors);

  const selectedSensor = useSelector(state => state.selectedSensor);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFetchingSensors && !hasFetchedSensors) {
      dispatch(doFetch('SENSORS', fetchSensorsFromApi()));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTab === 'map') {
      setShouldDisableListTab(true);
      setTimeout(() => setShouldDisableListTab(false), 750);
    }
  }, [selectedTab]);

  function formatLastReceivedTime() {
    if (selectedSensor.status.status === sensorStatuses.healthy) {
      return '';
    }
    if (selectedSensor.status.status === sensorStatuses.offline) {
      return translate('time.moreThanTwoDaysAgo');
    }

    return ` ${selectedSensor.status.timeAgoNumber} ${translate(selectedSensor.status.timeIntervalTranslateId)} ${translate('main.ago')}`;
  }

  const sensorStatusToColor = {
    healthy: theme.palette.success.light,
    unstable: theme.palette.warning.light,
    offline: theme.palette.error.light,
  };

  function rightSideContent() {
    if (isErrorFetchingSensors) {
      return (
        <Paper elevation={0} className={classes.infoErrorMessagePaper}>
          <Box display="flex" flexDirection="column">
            <Typography>
              <Translate id="main.errorFetchingSensors" />
            </Typography>
            <Button startIcon={<ReloadIcon />}
                    onClick={() => dispatch(doFetch('SENSORS', fetchSensorsFromApi()))}
                    variant="outlined"
                    color="primary">
              <Translate id="main.retry" />
            </Button>
          </Box>
        </Paper>
      );
    }
    if (isFetchingSensors) {
      return (
        <Paper elevation={0} className={classes.infoErrorMessagePaper}>
          <Typography>
            <Translate id="main.fetchingSensors" />
          </Typography>

          <CircularProgress style={{ margin: '1.5rem auto 0 auto' }} />
        </Paper>
      );
    }
    if (hasFetchedSensors && !isErrorFetchingSensors && !selectedSensor) {
      return (
        <Paper elevation={0} className={classes.infoErrorMessagePaper}>
          {selectedTab === 'map' ? (
            <Typography>
              <Translate id="main.clickMapToViewData" />
            </Typography>
          ) : (
            <Typography>
              <Translate id="main.selectFromListToViewData" />
            </Typography>
            )}
        </Paper>
      );
    }

    return (
      <Box display="flex" flexDirection="column">
        <Paper elevation={1} className={classes.graphPaper}>
          <Dust commonGraphClasses={commonGraphClasses} />
        </Paper>
        <Paper elevation={1} className={classes.graphPaper}>
          <Gases commonGraphClasses={commonGraphClasses} />
        </Paper>
        <Paper elevation={1} className={classes.graphPaper}>
          <Weather commonGraphClasses={commonGraphClasses} />
        </Paper>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container direction="row" className={classes.fullPadding}>
        <Grid item
              md={selectedSensor ? 4 : 8}
              sm={12}
              xs={12}
              className={classes.leftSideContainer}>
          <Tabs value={selectedTab}
                onChange={(event, newVal) => setSelectedTab(newVal)}
                centered
                indicatorColor="primary">
            <Tab label={<Translate id="main.map" />}
                 icon={<MapIcon />}
                 value="map"
                 id="map"
                 classes={{ wrapper: classes.tabWrapper, labelIcon: classes.tabWithIcon }} />
            <Tab label={<Translate id="main.list" />}
                 icon={<ListIcon />}
                 value="list"
                 id="list"
                 disabled={shouldDisableListTab}
                 classes={{ wrapper: classes.tabWrapper, labelIcon: classes.tabWithIcon }} />
          </Tabs>

          <Paper className={classes.mapContainer} style={{ display: selectedTab === 'map' ? 'block' : 'none' }}>
            <SensorMap isMapVisible={selectedTab === 'map'} />
          </Paper>
          <Paper className={classes.mapContainer} style={{ display: selectedTab === 'map' ? 'none' : 'block' }}>
            <SensorList />
          </Paper>
        </Grid>
        <Grid item md={selectedSensor ? 8 : 4} sm={12} xs={12} className={classes.rightSideContent}>
          {selectedSensor ? (
            <>
              <Box display="flex" flexDirection="row" alignItems="center" className={classes.sensorStatusRow}>
                <Typography variant="h1" className={classes.sensorName}>
                  {selectedSensor.deviceName}
                </Typography>

                <Hidden smDown>
                  <Paper elevation={1} className={classes.sensorStatus}>
                    <DotIcon fontSize="small" style={{ color: sensorStatusToColor[selectedSensor.status.status] }} />
                    <Typography>
                      Online
                      {formatLastReceivedTime()}
                    </Typography>
                  </Paper>
                </Hidden>
              </Box>
              <Hidden mdUp>
                <Box display="flex"
                     flexDirection="row"
                     alignItems="center"
                     justifyContent="center"
                     flexWrap="wrap"
                     className={classes.sensorStatusRowMobile}>

                  <Box className={classes.sensorStatus}>
                    <DotIcon fontSize="small"
                             style={{ color: sensorStatusToColor[selectedSensor.status.status] }} />
                    <Typography>
                      Online&nbsp;
                      {formatLastReceivedTime()}
                    </Typography>
                  </Box>
                </Box>
              </Hidden>

              <Box marginBottom="0.5rem" display="flex" alignItems="center">
                <InfoIcon color="primary" style={{ marginRight: '0.25rem' }} />
                <Typography variant="body1">
                  <Translate id="main.notFinishedSite" />
                </Typography>
              </Box>
            </>
          ) : (
            <Typography variant="h1" className={classes.airQualityHeader}>
              <Translate id="main.airQuality" />
            </Typography>
            )}
          {rightSideContent()}
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  airQualityHeader: {
    textAlign: 'center',
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  sensorStatusRow: {
    marginRight: '1rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
      padding: '0.25rem 0',
      justifyContent: 'center',
    },
    '&>*': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  sensorStatusRowMobile: {
    '&>*': {
      margin: '0 1rem 1rem 1rem',
    },
  },
  sensorName: {
    fontSize: '2.5rem',
    marginBottom: '3px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
  },
  mapContainer: {
    [theme.breakpoints.up('md')]: {
      height: '75vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '20rem',
    },
  },
  graphPaper: {
    marginBottom: '0.75rem',
    // border: '1px solid #ccc',
    padding: '0.5rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
    backgroundColor: 'white',
  },
  rightSideContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      '&>h4': {
        marginTop: '1rem',
      },
    },
  },
  leftSideContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: '0.25rem',
    },
  },
  fullPadding: {
    padding: '2rem 1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0.5rem',
    },
  },
  infoErrorMessagePaper: {
    padding: '2rem',
    margin: '1rem auto',
    width: 'fit-content',
    border: '1px solid #ccc',
    '&>div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& button': {
      alignSelf: 'flex-end',
      width: 'fit-content',
      marginTop: '1.5rem',
    },
  },
  sensorStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // border: '1px solid #ccc',
    borderRadius: '20px',
    padding: '0.1rem 0.5rem 0.1rem 0.3rem',
    '&>svg': {
      marginRight: '0.1rem',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 1.5rem',
      flexShrink: 0,
    },
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&>svg': {
      margin: '0 0.2rem 0 0 !important',
    },
  },
  tabWithIcon: {
    minHeight: '3.35rem !important',
  },
}));

const useGraphStyles = makeStyles(theme => ({
  rightSideResponsivePadding: {
    [theme.breakpoints.up('md')]: {
      padding: '0 0.75rem 0 1rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 0.5rem',
    },
  },
  alignStart: {
    alignSelf: 'flex-start',
  },
  selectedToggleButton: {
    color: 'white !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  dateButtonsContainer: {
    '& h4': {
      fontSize: '1.2rem',
    },
    justifyContent: 'space-between',
  },
  graphTitle: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 0 2px',
    },
  },
  graphInfoIcon: {
    marginLeft: '0.25rem',
  },
  dateRangePicker: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    marginLeft: '1rem',
    width: 'fit-content',
  },
  averageContainer: {
    flexWrap: 'wrap',
    marginLeft: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      width: '8.25rem',
      flexDirection: 'column',
      '&>p': {
        margin: '0.3rem 0 0 0',
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '&>*': {
        margin: '0 0.5rem',
      },
    },
  },
  graphAndAverageContainer: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  loadingBox: {
    [theme.breakpoints.up('md')]: {
      height: '9.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: '12.25rem',
    },
  },
  expandedLoadingBox: {
    [theme.breakpoints.up('md')]: {
      height: '270px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '18.5rem',
    },
  },
  mb025: {
    marginBottom: '0.25rem',
  },
  averageNumber: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginRight: '0.25rem',
  },
  retryButton: {
    width: 'fit-content',
    margin: '1rem auto 0 auto',
  },
  clickableLegend: {
    borderRadius: '12px',
    padding: '0.25rem',
    margin: 'inherit 0.25rem !important',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.075)',
    },
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    margin: 'auto',
    paddingLeft: '1rem',
    marginBottom: '-0.45rem',
    '&>div': {
      margin: '0 0.5rem 0.45rem 0.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&>p': {
        lineHeight: 1,
      },
    },
  },
  clickableLegendContainer: {
    '&>div': {
      margin: '0 0.5rem 0 0.5rem',
    },
  },
  refLine: {
    width: '1.25rem',
    marginRight: '0.2rem',
    borderBottomWidth: '3px',
    borderBottomStyle: 'solid',
  },
  mlr1: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  mr1: {
    marginRight: '1rem',
  },
}));

Content.propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ localize }) => ({
  translate: getTranslate(localize),
});
export default connect(mapStateToProps)(Content);
