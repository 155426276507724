import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, ReferenceLine,
} from 'recharts';
import ReloadIcon from '@material-ui/icons/Loop';
import { format } from 'date-fns';
import {
  Typography, Box, LinearProgress, Button,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import nb from 'date-fns/locale/nb';
import enGB from 'date-fns/locale/en-GB';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import DotOutlineIcon from '@material-ui/icons/FiberManualRecordOutlined';
import clsx from 'clsx';

const locales = {
  nb,
  en: enGB,
  nn: nb,
};

const dataKeys = ['NO', 'NO2', 'O3'];

const dataColors = {
  NO: '#006c8a',
  NO2: '#d90090',
  O3: '#e89f00',
};

const tooltipNames = { NO: 'NO', NO2: 'NO₂', O3: 'O₃' };
const tooltipUnits = { NO: 'µg/m³', NO2: 'µg/m³', O3: 'µg/m³' };

export default function GasGraph({
  loadDataFunction,
  isExpanded,
  dataPointSize,
  commonGraphClasses,
  shouldDisplayHours,
}) {
  const {
    sensorData: allSensorData,
    fetchError,
    isFetchingData,
  } = useSelector(state => state.gasData);

  const languages = useSelector(state => state.localize.languages);
  const activeLanguage = languages.find(item => item.active) || { code: 'nb' };
  const currentLocale = activeLanguage.code in locales ? locales[activeLanguage.code] : nb;
  const languageCode = activeLanguage?.code || 'nb';

  const [hiddenDataTypes, setHiddenDataTypes] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const selectedSensor = useSelector(state => state.selectedSensor);

  useEffect(() => {
    if (selectedSensor && selectedSensor.deviceID && Object.keys(allSensorData).length > 0) {
      if (selectedSensor.deviceID in allSensorData) {
        const { data } = allSensorData[selectedSensor.deviceID];
        setCurrentData(data);
      }
      else {
        setCurrentData([]);
      }
    }
  }, [selectedSensor, hiddenDataTypes, allSensorData]);

  function toggleDataTypeVisibility(dataType) {
    if (hiddenDataTypes.includes(dataType)) {
      setHiddenDataTypes(hiddenDataTypes.filter(dt => dt !== dataType));
    }
    else {
      setHiddenDataTypes([...hiddenDataTypes, dataType]);
    }
  }

  function formatTooltipLabel(timeString) {
    const date = new Date(timeString);
    const labelFormat = shouldDisplayHours ? 'PPPp' : 'PPP';
    return format(date, labelFormat, { locale: currentLocale });
  }

  function formatTooltipValue(value, labelName) {
    return ([
      `${new Intl.NumberFormat(languageCode).format(value)} ${tooltipUnits[labelName]}`,
      tooltipNames[labelName],
    ]);
  }

  function formatAxisDateTooltip(timeString) {
    const date = new Date(timeString);
    const firstTimestamp = currentData[0].time;
    const lastTimestamp = currentData[currentData.length - 1].time;
    const dateRange = new Date(lastTimestamp) - new Date(firstTimestamp);
    if (dateRange > 86400000 * 4) {
      return format(date, 'dd.MM');
    }
    return format(date, 'kk:mm');
  }

  function mainGraphContent() {
    const isThickGraphLine = currentData.length < 250;

    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" width="100%" padding="0 0.3rem" className={commonGraphClasses.graphAndAverageContainer}>
          <ResponsiveContainer height={isExpanded ? 300 : 140}>
            <LineChart data={currentData}>
              <CartesianGrid strokeDasharray="3,3" />
              <XAxis dataKey="time"
                     tickFormatter={timeStr => formatAxisDateTooltip(timeStr)}
                     tickLine={false} />
              <YAxis allowDecimals={false}
                     width={30} />

              {dataPointSize === 'hour' && (
                <ReferenceLine y={100}
                               stroke="#fa89d4"
                               strokeWidth={1.5} />
              )}

              <Tooltip labelFormatter={formatTooltipLabel}
                       formatter={formatTooltipValue}
                       separator=": " />

              {
                dataKeys.filter(dataType => !hiddenDataTypes.includes(dataType))
                  .map(dataType => (
                    <Line type="monotone"
                          dot={false}
                          dataKey={dataType}
                          key={dataType}
                          animationDuration={500}
                          stroke={dataColors[dataType]}
                          strokeWidth={isThickGraphLine ? 3 : 1} />
                  ))
              }
            </LineChart>
          </ResponsiveContainer>
        </Box>

        <RefLineLegend classes={commonGraphClasses}
                       dataPointSize={dataPointSize}
                       commonGraphClasses={commonGraphClasses}
                       toggleDataTypeVisibility={toggleDataTypeVisibility}
                       hiddenDataTypes={hiddenDataTypes} />
      </Box>
    );
  }

  function getContent() {
    if (fetchError) {
      return <ErrorContent commonGraphClasses={commonGraphClasses} loadDataFunction={loadDataFunction} />;
    }
    if (isFetchingData || !selectedSensor || !(selectedSensor.deviceID in allSensorData)) {
      return <LoadingContent commonGraphClasses={commonGraphClasses} isExpanded={isExpanded} />;
    }
    if (!fetchError && !isFetchingData) {
      if (currentData.length) {
        return mainGraphContent();
      }

      return <NoDataContent />;
    }
  }

  return (
    <Box width="100%" marginTop="0.3rem">
      {getContent()}
    </Box>
  );
}

GasGraph.propTypes = {
  loadDataFunction: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  dataPointSize: PropTypes.string.isRequired,
  commonGraphClasses: PropTypes.objectOf(PropTypes.string).isRequired,
  shouldDisplayHours: PropTypes.bool.isRequired,
};

const ErrorContent = ({ commonGraphClasses, loadDataFunction }) => (
  <Box width="100%" display="flex" flexDirection="column" padding="1rem" justifyContent="center">
    <Typography style={{ textAlign: 'center' }}>
      <Translate id="main.errorFetchingSensorData" />
    </Typography>
    <Button onClick={() => loadDataFunction()}
            variant="outlined"
            color="primary"
            startIcon={<ReloadIcon />}
            className={commonGraphClasses.retryButton}>
      <Translate id="main.retry" />
    </Button>
  </Box>
);
ErrorContent.propTypes = {
  commonGraphClasses: PropTypes.any.isRequired,
  loadDataFunction: PropTypes.func.isRequired,
};

const LoadingContent = ({ isExpanded, commonGraphClasses }) => (
  <Box width="100%"
       display="flex"
       flexDirection="column"
       justifyContent="center"
       className={isExpanded ? commonGraphClasses.expandedLoadingBox : commonGraphClasses.loadingBox}>
    <LinearProgress />
  </Box>
);
LoadingContent.propTypes = {
  commonGraphClasses: PropTypes.any.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

const NoDataContent = () => (
  <Box width="100%" display="flex" flexDirection="column" padding="1rem" justifyContent="center">
    <Typography style={{ textAlign: 'center' }}>
      <Translate id="main.noDataInSelectedInterval" />
    </Typography>
  </Box>
);

const RefLineLegend = ({
  classes, dataPointSize, toggleDataTypeVisibility, hiddenDataTypes, commonGraphClasses,
}) => (
  <Box className={clsx(classes.legendContainer, classes.clickableLegendContainer)}>
    {Object.entries(dataColors).map(([dataType, dataColor]) => {
        const isHidden = hiddenDataTypes.includes(dataType);

        return (
          <Box display="flex"
               flexDirection="row"
               key={dataType}
               onClick={() => toggleDataTypeVisibility(dataType)}
               className={commonGraphClasses.clickableLegend}>
            {isHidden ? (
              <DotOutlineIcon fontSize="small" style={{ color: dataColor }} />
            ) : (
              <DotIcon fontSize="small" style={{ color: dataColor }} />
            )}
            <Typography variant="body2">
              {dataType}
            </Typography>
          </Box>
        );
      })}

    {dataPointSize === 'hour' && (
    <Box display="flex" flexDirection="row">
      <div className={classes.refLine}
           style={{ borderBottomColor: '#fa89d4' }} />

      <Typography variant="body2">
        <Translate id="domain.hourlyRequirement" />
        NO₂&nbsp;
        <Translate id="main.and" />
                &nbsp;O₃ (100)
      </Typography>
    </Box>
      )}
  </Box>
);

RefLineLegend.propTypes = {
  classes: PropTypes.any.isRequired,
  dataPointSize: PropTypes.string.isRequired,
  toggleDataTypeVisibility: PropTypes.func.isRequired,
  hiddenDataTypes: PropTypes.array.isRequired,
  commonGraphClasses: PropTypes.object.isRequired,
};
