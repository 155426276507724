import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import { Typography, DialogContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function DustInfo({
  open,
  onClose,
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.infoDialogTitle}>
        <Translate id="infoBoxContent.dustHeader" />
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.infoDialog}>
        <Typography>
          <Translate id="infoBoxContent.dustIntro" />
        </Typography>
        <Typography>
          <Translate id="infoBoxContent.dustDefinitions" />
        </Typography>
        <Typography>
          <Translate id="infoBoxContent.dustRequirements" />
        </Typography>
        <Typography>
          <Link href="https://www.fhi.no/nettpub/luftkvalitet/svevestov/svevestov/" target="_blank">
            <Translate id="infoBoxContent.readMoreAboutDustOnFHIno" />
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  infoDialogTitle: {
    padding: '1rem 2rem 0.5rem 2rem',
  },
  infoDialog: {
    padding: '0 2rem 2rem 2rem',
    '& p:not(:first-child)': {
      marginTop: '1.5rem',
    },
    '& a': {
      textDecoration: 'underline',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

DustInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
