export function addSensorIdToUrl(history, newSensorID) {
  let path = history.location.pathname.split('/');
  if (path.length === 2 && path[0] === '' && path[1] === '') {
    path = [];
  }
  path = path.slice(0, path.length - 1);
  path.push(newSensorID);
  const newPath = path.join('/');
  history.replace(newPath);
}

export function clearUrl(history) {
  const splitPath = history.location.pathname.split('/');
  let shorterPath = splitPath.slice(0, splitPath.length - 1).join('/');
  if (!shorterPath.endsWith('/')) { shorterPath += '/'; }
  history.replace(shorterPath);
}
