/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withLocalize } from 'react-localize-redux';

import {
  BrowserRouter, Route, Switch, Link,
} from 'react-router-dom';
import NavBar from '@trdk/style/NavBar';
import LanguageSelector from '@trdk/style/LanguageSelector';

import MainPage from './pages/content';

import Theme from './theme';


function App({ languages, activeLanguage, setActiveLanguage }) {
  useEffect(() => {
    const existingLanguage = localStorage.getItem('language');
    if (!existingLanguage && languages) {
      const norwegian = languages.find(lang => lang.code === 'nb')?.code;
      setLanguage(norwegian);
    }
  }, []);

  const setLanguage = (languageCode) => {
    localStorage.setItem('language', languageCode);
    document.documentElement.lang = languageCode;
    setActiveLanguage(languageCode);
  };


  return (
    <BrowserRouter basename="/">
      <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <NavBar withLinkProps={{ url: '/', Link }} variant="styled" language={activeLanguage.code}>
          <LanguageSelector languages={languages}
                            setLang={setLanguage}
                            flavour={{}} />
        </NavBar>
        <Switch>
          <Route path="/" exact>
            <MainPage />
          </Route>
          <Route path="/:sensorID" exact>
            <MainPage />
          </Route>
          <Route>
            <MainPage />
          </Route>
        </Switch>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

App.propTypes = {
  languages: PropTypes.array,
  activeLanguage: PropTypes.object,
  setActiveLanguage: PropTypes.func,
};

export default withLocalize(App);
