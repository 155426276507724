import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import {
  ListItem, List, ListItemText, Tooltip, Typography,
} from '@material-ui/core';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import { useSelector, useDispatch } from 'react-redux';
import { addSensorIdToUrl } from '../../services/url-helper';

export default function SensorList() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { payload: allSensors } = useSelector(state => state.sensors);
  const selectedSensor = useSelector(state => state.selectedSensor);

  const sensorStatusToColor = {
    healthy: theme.palette.success.light,
    unstable: theme.palette.warning.light,
    offline: theme.palette.error.light,
  };

  function onSensorClicked(sensor) {
    addSensorIdToUrl(history, sensor.deviceID);

    dispatch({
      type: 'SET_SELECTED_SENSOR',
      value: sensor,
    });
  }

  return (
    <List className={classes.sensorList}>
      {allSensors.map(sensor => (
        <ListItem divider
                  classes={{ selected: classes.selectedListItem }}
                  dense
                  button
                  selected={selectedSensor && selectedSensor.deviceID === sensor.deviceID}
                  key={sensor.deviceID}
                  onClick={() => onSensorClicked(sensor)}>
          <ListItemText primary={`${sensor.deviceNo}: ${sensor.deviceName}`} />
          <Tooltip title={(
            <Typography>
              Status:&nbsp;
              <Translate id={sensor.status.statusTextTranslateId} />
            </Typography>
          )}>
            <DotIcon fontSize="small" style={{ color: sensorStatusToColor[sensor.status.status] }} />
          </Tooltip>
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStyles(theme => ({
  selectedListItem: {
    borderLeft: `0.2rem solid ${theme.palette.primary.main}`,
    '&>*': {
      marginLeft: '-0.2rem',
    },
  },
  sensorList: {
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '75vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '20rem',
    },
  },
}));
