import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import {
  Typography, DialogContent, Dialog, Link, DialogTitle, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function DustInfo({
  open,
  onClose,
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.infoDialogTitle}>
        <Translate id="infoBoxContent.weatherInfoHeader" />
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.infoDialog}>
        <Typography variant="body1">
          <Translate id="infoBoxContent.weatherIntro1" />
          <Link href="https://www.met.no" target="_blank" underline="always">
            <Translate id="infoBoxContent.met" />
          </Link>
          <Translate id="infoBoxContent.weatherIntro2" />
          <Link href="https://frost.met.no" target="_blank" underline="always">
            frost.met.no
          </Link>
          .
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  infoDialogTitle: {
    padding: '1rem 2rem 0.5rem 2rem',
  },
  infoDialog: {
    padding: '0 2rem 2rem 2rem',
    '& p:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

DustInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
