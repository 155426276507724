import React, { useState } from 'react';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import nb from 'date-fns/locale/nb';
import enGB from 'date-fns/locale/en-GB';
import {
  format as dFormat,
} from 'date-fns';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Calendar from './calendar';

enGB.trans = {
  dateDisabledBefore: 'Date is invalid, it cannot be before today',
  dateDisabledAfter: 'Date is invalid, it cannot be after today',
  dateStringInvalid: 'The date is invalid',
};

nb.trans = {
  dateDisabledBefore: 'Datoen er ugyldig, kan ikke være før dags dato',
  dateDisabledAfter: 'Datoen er ugyldig, kan ikke være etter dags dato',
  dateStringInvalid: 'Datoen er ugyldig',
};

const locales = {
  nb,
  en: enGB,
  nn: nb,
};

const DialogComp = ({
  children, open, handleClose,
}) => (
  <Dialog aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
          PaperProps={{ elevation: 0 }}>
    <div>
      {children}
    </div>
  </Dialog>
);

DialogComp.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const PopoverComp = ({
  children, handleClose, anchorEl, open,
}) => (
  <Popover id="calendar-popper"
           open={open}
           anchorEl={anchorEl}
           onClose={handleClose}
           anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
           transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}>
    {children}
  </Popover>
);

PopoverComp.propTypes = {
  anchorEl: PropTypes.object,
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

PopoverComp.defaultProps = {
  anchorEl: null,
};

const dialogComps = {
  xs: DialogComp,
  sm: DialogComp,
  md: PopoverComp,
  lg: PopoverComp,
};

function SubmitAndCancelButtons({
  // eslint-disable-next-line react/prop-types
  handleClose, onSearch, search, submitLabel, cancelLabel,
}) {
  return (
    <>
      {onSearch && (
        <CalQuickBut variant="outlined"
                     key="cancelBut"
                     onClick={() => {
            handleClose(true);
          }}>
          {cancelLabel}
        </CalQuickBut>
      )}
      <CalQuickBut key="doneBut"
                   onClick={(e) => {
          handleClose();
          if (onSearch) {
            search(e);
          }
        }}>
        {submitLabel}
      </CalQuickBut>
    </>
  );
}

const CalQuickBut = ({
  // eslint-disable-next-line react/prop-types
  children, dateFrom, dateTo, onChange, onClick, search, handleClose, variant = 'contained',
}) => {
  const classes = useStyles();
  return (
    <Button color="primary"
            variant={variant}
            className={classes.calButton}
            onClick={onClick || ((e) => {
        onChange('from', dateFrom || new Date());
        onChange('to', dateTo || new Date()); // use today if no dateTo specified

        if (search) {
          search(e, dateFrom || new Date(), dateTo || new Date());
        }

        if (handleClose) {
          handleClose();
        }
      })}>
      {children}
    </Button>
  );
};

const useStyles = makeStyles(theme => ({
  dateSpan: {
    whiteSpace: 'nowrap',
  },
  calMaster: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse !important',
    },
    margin: '0px',
  },
  calendarContainer: {
    margin: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  calendars: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  horDivider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  verDivider: {
    width: '100%',
    height: 1,
    margin: 8,
  },
  searchPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  rangeDisplay: {
    marginLeft: 8,
    marginRight: 8,
    flex: 1,
    cursor: 'pointer',
  },
  iconButton: {
    padding: 8,
  },
  buttonList: {
    minWidth: '13rem',
    maxWidth: '18rem',
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    '& button': {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
    },
  },
  calButton: {
    width: '100%',
  },
  noMarginTop: {
    marginTop: 0,
  },
}));

const DateRangePicker = ({
  from,
  to,
  onChange,
  locale,
  format,
  fromLabel,
  toLabel,
  toProps,
  fromProps,
  onSearch,
  showSearchIcon,
  submitLabel,
  cancelLabel,
  placeholder,
  quickButs,
  width, // eslint-disable-line react/prop-types
  className,
  elevation,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [originalFrom, setOriginalFrom] = useState(from);
  const [originalTo, setOriginalTo] = useState(to);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(reset) {
    setAnchorEl(null);
    if (onSearch && reset) {
      onChange('from', originalFrom);
      onChange('to', originalTo);
    }
  }

  const search = (e, dateFrom, dateTo) => {
    onSearch(e, dateFrom, dateTo);
    setOriginalFrom(from);
    setOriginalTo(to);
  };

  const open = Boolean(anchorEl);
  const loc = locale in locales ? locales[locale] : nb;
  const DialogUse = dialogComps[width] || PopoverComp;
  return (
    <div className={clsx(className)}>
      <Paper className={classes.searchPaper}
             elevation={elevation}>
        <IconButton aria-owns={open ? 'calendar-popper' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.iconButton}
                    aria-label="open date range popup">
          <DateRangeIcon />
        </IconButton>
        <Typography id="outlined-name"
                    className={classes.rangeDisplay}
                    onChange={() => {}} // this.parseTextFieldData}
                    onClick={handleClick}>
          {
            (
              from && to && (
                [
                  <span key="from">{`${dFormat(from, format, { locale: loc })} - `}</span>,
                  <span className={classes.dateSpan} key="to">{dFormat(to, format, { locale: loc })}</span>,
                ]
              )
            ) || placeholder
          }
        </Typography>
        {
          onSearch && showSearchIcon && [
            <Divider key="div" className={classes.horDivider} />,
            <IconButton className={classes.iconButton} key="search" onClick={search}>
              <SearchIcon />
            </IconButton>,
          ]
        }
      </Paper>
      <DialogUse open={open}
                 anchorEl={anchorEl}
                 handleClose={handleClose}
                 classes={classes}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}
                                 locale={loc}>
          <Paper className={classes.calMaster} style={{ flexDirection: quickButs ? 'row' : 'column' }}>
            <Hidden mdUp>
              <div className={classes.calendarContainer}>
                <Paper elevation={0} className={classes.buttonRow}>
                  <SubmitAndCancelButtons cancelLabel={cancelLabel}
                                          submitLabel={submitLabel}
                                          handleClose={handleClose}
                                          onSearch={onSearch}
                                          search={search} />
                </Paper>
              </div>
            </Hidden>
            <div className={classes.calendars}>
              <Calendar label={fromLabel}
                        value={from}
                        fieldName="from"
                        calendarProps={fromProps}
                        onChange={onChange}
                        locale={loc}
                        onEnterKeyPress={(e) => {
                  handleClose();
                  if (onSearch) {
                    search(e);
                  }
                }} />
              <Calendar label={toLabel}
                        value={to}
                        fieldName="to"
                        calendarProps={toProps}
                        onChange={onChange}
                        locale={loc}
                        onEnterKeyPress={(e) => {
                  handleClose();
                  if (onSearch) {
                    search(e);
                  }
                }} />
            </div>
            <div className={clsx(classes.calendarContainer, quickButs ? undefined : classes.noMarginTop)}>
              <Paper elevation={0} className={quickButs ? classes.buttonList : classes.buttonRow}>
                { quickButs && quickButs.map(
                  ({ dateFrom, dateTo, label }) => (
                    <CalQuickBut key={label}
                                 dateFrom={dateFrom}
                                 dateTo={dateTo}
                                 onChange={onChange}
                                 search={onSearch && search}
                                 handleClose={handleClose}>
                      {label}
                    </CalQuickBut>
                  ),
                )}
                <Hidden smDown>
                  {quickButs && <Divider key="div" className={classes.verDivider} />}
                  <SubmitAndCancelButtons cancelLabel={cancelLabel}
                                          submitLabel={submitLabel}
                                          handleClose={handleClose}
                                          onSearch={onSearch}
                                          search={search} />
                </Hidden>
              </Paper>
            </div>
          </Paper>
        </MuiPickersUtilsProvider>
      </DialogUse>
    </div>
  );
};

DateRangePicker.propTypes = {
  /** Label on 'done'-button to close the popover. Calls onSearch if onSearch is specified */
  submitLabel: PropTypes.any,
  /** Label on 'cancel'-button to close the popover without calling onSearch when onSearch is
   * specified */
  cancelLabel: PropTypes.any,
  /** Label over 'from'-calendar */
  fromLabel: PropTypes.any,
  /** Label over 'to'-calendar */
  toLabel: PropTypes.any,
  /** DateFns format på dato-visning */
  format: PropTypes.string,
  /** Lokalisering, støttet nå er: en (Britisk), nb (Norsk), nn (Samme som nb) */
  locale: PropTypes.string,
  /** Date 'fra'-objekt */
  from: PropTypes.instanceOf(Date).isRequired,
  /** Date 'til'-objekt */
  to: PropTypes.instanceOf(Date).isRequired,
  /** onChange blir kalt hver gang en av datoene endrer seg med to argumenter:
   * ('from'|'to', Date) */
  onChange: PropTypes.func.isRequired,
  /** Props som sendes direkte inn i 'to'date-picker-en */
  toProps: PropTypes.object,
  /** Props som sendes direkte inn i 'from'date-picker-en */
  fromProps: PropTypes.object,
  /** Funksjon som blir kalt når søkeknappen trykkes eller submit-knappen trykkes i popover-en.
   * Søkeknappen vises om denne er definert */
  onSearch: PropTypes.func,
  /** Synlighet av knapp til høyre som kan trykkes for å "search" selv når popup er lukket */
  showSearchIcon: PropTypes.bool,
  /** Tekst som står om fra og til ikke er spesifisert */
  placeholder: PropTypes.string,
  /** Liste med definisjoner for predefinerte intervaller.
   * Feks: [{dateFrom: subDays(new Date(), 15), dateTo: new Date(), label: 'Siste 15 dager'}] */
  quickButs: PropTypes.array,
  /** className blir satt på SearchPaper, den boksen som viser valgte datoer */
  className: PropTypes.any,
  /** elevation prop passed to MuiPaper */
  elevation: PropTypes.number,
};

DateRangePicker.defaultProps = {
  submitLabel: 'Done',
  cancelLabel: 'Cancel',
  fromLabel: 'From',
  toLabel: 'To',
  toProps: {},
  fromProps: {},
  locale: 'nb',
  format: 'PPP',
  onSearch: undefined,
  placeholder: '',
  quickButs: undefined,
  className: undefined,
  elevation: 1,
};

export default withWidth()(DateRangePicker);
