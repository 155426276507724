/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import {
  Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, ReferenceLine, CartesianGrid,
} from 'recharts';
import ReloadIcon from '@material-ui/icons/Loop';
import {
  Typography, Box, LinearProgress, Button,
} from '@material-ui/core';
import { format, isSameDay, isSameHour } from 'date-fns';
import { useSelector } from 'react-redux';
import nb from 'date-fns/locale/nb';
import enGB from 'date-fns/locale/en-GB';
import DotIcon from '@material-ui/icons/FiberManualRecord';

const colors = {
  'PM2.5': '#e89f00',
  PM10: '#006c8a',
  NILUPM10: '#292b2d',
  NILUPM25: '#74777d',
};
const refLineColors = { 'PM2.5': '#f5c867', PM10: '#60d7f7', PM10Hard: 'red' };
const keysNotDataTypes = ['time', 'displayedPM10', 'NILUdisplayedPM10'];
const dataTypeToKeyName = {
  'PM2.5': 'PM₂.₅',
  PM10: 'PM₁₀₋₂.₅',
  NILUPM10: 'NILU PM₁₀₋₂.₅',
  NILUPM25: 'NILU PM₂.₅',
};

const locales = { nb, en: enGB, nn: nb };

export default function DustGraph({
  loadDataFunction,
  isExpanded = false,
  shouldDisplayHours,
  commonGraphClasses,
  dataPointSize,
  activeLanguage: { code: languageCode },
}) {
  const [hasNILUData, setHasNILUData] = useState(false);
  const [combinedSensorData, setCombinedSensorData] = useState([]);
  const [dataTypes, setDataTypes] = useState([]);

  const {
    sensorData,
    fetchError,
    isFetchingData,
  } = useSelector(state => state.dustData);

  const { payload: NILUDustData } = useSelector(state => state.sensorNILUDustData);

  const languages = useSelector(state => state.localize.languages);
  const activeLanguage = languages.find(item => item.active) || { code: 'nb' };
  const currentLocale = activeLanguage.code in locales ? locales[activeLanguage.code] : nb;
  const selectedSensor = useSelector(state => state.selectedSensor);

  useEffect(() => {
    if (!sensorData || !selectedSensor || !sensorData || !(selectedSensor.deviceID in sensorData)) {
      return;
    }

    let newSensorData = [];
    if (NILUDustData) {
      const tkSensorData = sensorData[selectedSensor.deviceID].data;
      const NILUData = [...NILUDustData];
      let foundSomeNILUData = false;

      tkSensorData.forEach((tkDataPoint) => {
        let NILUDataPointIndex;

        if (shouldDisplayHours) {
          NILUDataPointIndex = NILUData.findIndex(NILUDP => isSameHour(new Date(NILUDP.time), new Date(tkDataPoint.time)));
        }
        else {
          NILUDataPointIndex = NILUData.findIndex(NILUDP => isSameDay(new Date(NILUDP.time), new Date(tkDataPoint.time)));
        }

        let combinedDataPoint;
        if (NILUDataPointIndex >= 0) {
          foundSomeNILUData = true;
          const NILUDataPoint = NILUData.splice(NILUDataPointIndex, 1)[0];
          combinedDataPoint = {
            ...tkDataPoint,
            NILUPM10: NILUDataPoint?.PM10 || 0,
            NILUdisplayedPM10: NILUDataPoint?.displayedPM10 || 0,
            NILUPM25: NILUDataPoint?.PM25 || 0,
          };
        }
        else {
          combinedDataPoint = tkDataPoint;
        }

        newSensorData.push(combinedDataPoint);
      });

      setHasNILUData(foundSomeNILUData);
    }
    else {
      setHasNILUData(false);
      newSensorData = sensorData[selectedSensor.deviceID].data;
    }

    setCombinedSensorData(newSensorData);

    const allDataTypes = new Set();
    setDataTypes(newSensorData.forEach((dataPoint) => {
      Object.keys(dataPoint).filter(dp => !keysNotDataTypes.includes(dp)).forEach((dp) => {
        allDataTypes.add(dp);
      });
    }));
    setDataTypes([...allDataTypes]);
  }, [sensorData, NILUDustData]);

  function formatTooltipLabel(millisecondValue) {
    const date = new Date(millisecondValue);
    const labelFormat = shouldDisplayHours ? 'PPPp' : 'PPP';
    return format(date, labelFormat, { locale: currentLocale });
  }

  function formatTooltipValue(value, labelName, fullInfo) {
    if (labelName === 'displayedPM10') {
      return [`${new Intl.NumberFormat(languageCode).format(fullInfo.payload.PM10)} µg/m³`, 'PM₁₀'];
    }
    if (labelName === 'PM2.5') {
      return ([`${new Intl.NumberFormat(languageCode).format(value)} µg/m³`, 'PM₂.₅']);
    }
    if (labelName === 'NILUdisplayedPM10') {
      return [`${new Intl.NumberFormat(languageCode).format(fullInfo.payload.NILUPM10)} µg/m³`, 'NILU PM₁₀'];
    }

    return [`${new Intl.NumberFormat(languageCode).format(value)} µg/m³`, 'NILU PM₂.₅'];
  }

  function formatAxisDateTooltip(timeString) {
    const date = new Date(timeString);
    if (shouldDisplayHours) {
      return format(date, 'HH:mm');
    }
    return format(date, 'dd.MM');
  }

  function RefLineLegend() {
    return (
      <Box className={commonGraphClasses.legendContainer}>
        {dataTypes.map(dataType => (
          <Box display="flex" flexDirection="row" key={dataType}>
            <DotIcon fontSize="small" style={{ color: colors[dataType] }} />
            <Typography variant="body2">
              {dataTypeToKeyName[dataType]}
            </Typography>
          </Box>
        ))}

        {dataPointSize === 'day' && (
          <>
            <Box display="flex" flexDirection="row">
              <div className={commonGraphClasses.refLine}
                   style={{ borderBottomColor: refLineColors['PM2.5'] }} />
              <Typography variant="body2">
                <Translate id="domain.dailyRequirement" />
                PM₂.₅ (15)
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row">
              <div className={commonGraphClasses.refLine}
                   style={{ borderBottomColor: refLineColors.PM10 }} />
              <Typography variant="body2">
                <Translate id="domain.dailyRequirement" />
                PM₁₀ (30)
              </Typography>
            </Box>
          </>
        )}
      </Box>
    );
  }

  function noDataContent() {
    return (
      <Box width="100%" display="flex" padding="1rem" flexDirection="column" justifyContent="center">
        <Typography style={{ textAlign: 'center' }}>
          <Translate id="main.noDataInSelectedInterval" />
        </Typography>
      </Box>
    );
  }

  function loadingContent() {
    return (
      <Box width="100%"
           display="flex"
           flexDirection="column"
           justifyContent="center"
           className={
            isExpanded ? commonGraphClasses.expandedLoadingBox : commonGraphClasses.loadingBox}>
        <LinearProgress />
      </Box>
    );
  }

  function errorContent() {
    return (
      <Box width="100%" display="flex" flexDirection="column" padding="1rem" justifyContent="center">
        <Typography style={{ textAlign: 'center' }}>
          <Translate id="main.errorFetchingSensorData" />
        </Typography>
        <Button onClick={() => loadDataFunction()}
                variant="outlined"
                color="primary"
                startIcon={<ReloadIcon />}
                className={commonGraphClasses.retryButton}>
          <Translate id="main.retry" />
        </Button>
      </Box>
    );
  }

  function mainGraphContent() {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" width="100%" padding="0 0.3rem" className={commonGraphClasses.graphAndAverageContainer}>
          <ResponsiveContainer height={isExpanded ? 300 : 140}>
            <BarChart data={combinedSensorData}>
              <CartesianGrid strokeDasharray="3,3" />
              <XAxis dataKey="time"
                     tickFormatter={timeStr => formatAxisDateTooltip(timeStr)} />
              <YAxis allowDecimals={false}
                     width={30} />

              <Tooltip labelFormatter={formatTooltipLabel}
                       formatter={formatTooltipValue}
                       separator=": "
                       cursor={{ fill: '#dddddd' }} />

              <Bar stackId="x"
                   dataKey="PM2.5"
                   fill={colors['PM2.5']}
                   animationDuration={500}
                   strokeWidth={2} />

              <Bar stackId="x"
                   dataKey="displayedPM10"
                   fill={colors.PM10}
                   animationDuration={500}
                   strokeWidth={2} />

              {combinedSensorData.length > 0 && hasNILUData && (
                <Bar stackId="x2"
                     dataKey="NILUPM25"
                     fill={colors.NILUPM25}
                     animationDuration={500}
                     strokeWidth={2} />
              )}

              {combinedSensorData.length > 0 && hasNILUData && (
                <Bar stackId="x2"
                     dataKey="NILUdisplayedPM10"
                     fill={colors.NILUdisplayedPM10}
                     animationDuration={500}
                     strokeWidth={2} />
              )}

              {dataPointSize === 'day' && (
                [
                  <ReferenceLine y={15} stroke={refLineColors['PM2.5']} strokeWidth={2} key="refLine1" />,
                  <ReferenceLine y={30} stroke={refLineColors.PM10} strokeWidth={2} key="refLine2" />,
                ]
              )}
            </BarChart>
          </ResponsiveContainer>
        </Box>

        <RefLineLegend />
      </Box>
    );
  }

  function getContent() {
    if (fetchError) {
      return errorContent();
    }
    if (isFetchingData || !selectedSensor || !(selectedSensor.deviceID in sensorData)) {
      return loadingContent();
    }
    if (!fetchError && !isFetchingData) {
      if (sensorData[selectedSensor.deviceID].data.length) {
        return mainGraphContent();
      }

      return noDataContent();
    }
  }

  return (
    <Box width="100%" marginTop="0.3rem">
      {getContent()}
    </Box>
  );
}

DustGraph.propTypes = {
  loadDataFunction: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  dataPointSize: PropTypes.string.isRequired,
  commonGraphClasses: PropTypes.objectOf(PropTypes.string).isRequired,
  activeLanguage: PropTypes.object,
  shouldDisplayHours: PropTypes.bool.isRequired,
};

DustGraph.defaultProps = {
  activeLanguage: {},
};
