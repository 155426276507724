/* eslint-disable no-use-before-define */
import { format } from 'date-fns';
import {
  processDustData, processGasData, calculateLastReceivedStatus, processNILUDustData,
} from './data-processing-helper';

const urlPrefix = '/api';

export async function fetchAllSensors() {
  const response = await fetch(`${urlPrefix}/v1/sensors`);
  let data = await response.json();

  data = data
    .filter(s => s.visible)
    .map(s => ({
      deviceID: s.deviceID,
      deviceName: s.deviceName || 'Sensor uten navn',
      deviceNo: Number(s.deviceNo),
      lat: (s.lat * 180) / Math.PI,
      lon: (s.lon * 180) / Math.PI,
      status: calculateLastReceivedStatus(s.lastReceivedMsg),
      data: null,
      dataFetchedTime: null,
    }))
    .sort((s1, s2) => (s1.deviceNo > s2.deviceNo ? 1 : -1));

  return data;
}

export async function fetchSensorDustData(sensorId, startDate, endDate, dataPointSize) {
  try {
    const dustUrl = makeRequestUrl('dust', sensorId, startDate, endDate, dataPointSize);
    const response = await fetch(dustUrl);
    const responseData = await response.json();

    const { data, averages } = processDustData(responseData);

    return { data, averages };
  } catch (err) {
    throw err;
  }
}

export async function fetchSensorGasData(sensorId, startDate, endDate, dataPointSize) {
  try {
    const gasUrl = makeRequestUrl('gases', sensorId, startDate, endDate, dataPointSize);
    const response = await fetch(gasUrl);
    const responseData = await response.json();

    const { data, averages } = processGasData(responseData);

    return { data, averages };
  } catch (err) {
    throw err;
  }
}

export async function getNILUDustData(sensorId, startDate, endDate, dataPointSize) {
  try {
    let sensorQueryName;
    let startDateQuery;
    let endDateQuery;
    let apiUrl;

    if (sensorId === '17dh0cf43jg89n' || sensorId === '2f3a11687f7a2j') {
      sensorQueryName = 'Torvet';
    } else if (sensorId === '17dh0cf43jg89l') {
      sensorQueryName = 'Elgeseter';
    }

    if (dataPointSize === 'hour') {
      startDateQuery = format(startDate, 'yyyy-MM-dd HH:00');
      endDateQuery = format(endDate, 'yyyy-MM-dd HH:00');
      apiUrl = 'https://api.nilu.no/obs/historical';
    } else {
      startDateQuery = format(startDate, 'yyyy-MM-dd');
      endDateQuery = format(endDate, 'yyyy-MM-dd');
      apiUrl = 'https://api.nilu.no/agg/2';
    }

    const fullUrl = `${apiUrl}/${startDateQuery}/${endDateQuery}/${sensorQueryName}?components=pm10;pm2.5`;

    const rawResponse = await fetch(fullUrl);
    if (!rawResponse.ok) { throw new Error(); }

    const response = await rawResponse.json();

    const processedData = processNILUDustData(response);
    return processedData;
  } catch (err) {
    throw err;
  }
}

function makeRequestUrl(dataType, sensorId, startDate, endDate, dataPointSize) {
  const url = `${urlPrefix}/v1/sensors/${sensorId}/${dataType}?from=${startDate.getTime()}&to=${endDate.getTime()}&dataPointSize=${dataPointSize}`;
  return url;
}
