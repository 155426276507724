import {
  FETCH_GAS_DATA_REQUEST, FETCH_GAS_DATA_SUCCESS, FETCH_GAS_DATA_ERROR, GET_GAS_DATA_FROM_CACHE,
} from './gasData.actions';

export default function (
  state = {
    isFetchingData: false,
    fetchError: false,
    sensorData: {},
  },
  action,
) {
  switch (action.type) {
    case FETCH_GAS_DATA_REQUEST: {
      return {
        ...state,
        isFetchingData: true,
        fetchError: false,
      };
    }

    case FETCH_GAS_DATA_SUCCESS: {
      return {
        isFetchingData: false,
        fetchError: false,
        sensorData: {
          ...state.sensorData,
          [action.sensorId]: {
            fetchedTime: new Date(),
            data: action.data,
            averages: action.averages,
            interval: action.interval,
          },
        },
      };
    }

    case GET_GAS_DATA_FROM_CACHE: {
      return {
        ...state,
        isFetchingData: false,
        fetchError: false,
      };
    }

    case FETCH_GAS_DATA_ERROR: {
      return {
        ...state,
        isFetchingData: false,
        fetchError: true,
      };
    }

    default:
      return state;
  }
}
