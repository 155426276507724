import { differenceInMinutes } from 'date-fns';
import { fetchSensorDustData } from '../../services/sensor-service';

export const FETCH_DUST_DATA_REQUEST = 'FETCH_DUST_DATA_REQUEST';
export const FETCH_DUST_DATA_SUCCESS = 'FETCH_DUST_DATA_SUCCESS';
export const FETCH_DUST_DATA_ERROR = 'FETCH_DUST_DATA_ERROR';
export const GET_DUST_DATA_FROM_CACHE = 'GET_DUST_DATA_FROM_CACHE';

function getDustDataFromCache(sensorId, interval, dataPointSize) {
  return {
    type: GET_DUST_DATA_FROM_CACHE,
    sensorId,
    interval,
    dataPointSize,
  };
}

function fetchDustDataRequest(sensorId, interval, dataPointSize) {
  return {
    type: FETCH_DUST_DATA_REQUEST,
    sensorId,
    interval,
    dataPointSize,
  };
}

function fetchDustDataSuccess(data, averages, sensorId, interval, dataPointSize) {
  return {
    type: FETCH_DUST_DATA_SUCCESS,
    data,
    averages,
    sensorId,
    interval,
    dataPointSize,
  };
}

function fetchDustDataError() {
  return {
    type: FETCH_DUST_DATA_ERROR,
  };
}

export function getSensorDustData(sensorId, from, to, interval, dataPointSize) {
  return (dispatch, getState) => {
    const isCached = isDataCached(sensorId, getState().dustData.sensorData, interval, dataPointSize);
    if (isCached) {
      dispatch(getDustDataFromCache(sensorId, interval, dataPointSize));
    }
    else {
      dispatch(fetchDustDataRequest());
      fetchSensorDustData(sensorId, from, to, dataPointSize)
        .then(response => dispatch(
          fetchDustDataSuccess(response.data, response.averages, sensorId, interval, dataPointSize),
        ))
        .catch(() => dispatch(fetchDustDataError()));
    }
  };
}

function isDataCached(sensorId, cachedData, newInterval, newDataPointSize) {
  if (!(sensorId in cachedData)) {
    return false;
  }

  const { fetchedTime, interval, dataPointSize } = cachedData[sensorId];
  const minutesSinceFetched = differenceInMinutes(fetchedTime, new Date());

  return interval === newInterval
    && dataPointSize === newDataPointSize
    && minutesSinceFetched <= 2;
}
