import React, { useState, useRef, useEffect } from 'react';
import { Calendar as MuiCalendar } from '@material-ui/pickers';
import {
  format as dtFormat,
  parse as dtParse,
  getDate,
  isSameDay,
} from 'date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import TodayIcon from '@material-ui/icons/Today';
import Day from './day';


const dfnsUtils = new DateFnsUtils();

const validateMinMaxDate = (day, minDate, maxDate) => (
  (minDate && dfnsUtils.isBeforeDay(day, minDate))
    || (maxDate && dfnsUtils.isAfterDay(day, maxDate))
);

const shouldDisableDate = (day, disablePast, disableFuture, minDate, maxDate) => Boolean(
  (disableFuture && dfnsUtils.isAfterDay(day, new Date()))
      || (disablePast && dfnsUtils.isBeforeDay(day, new Date()))
      || validateMinMaxDate(day, minDate, maxDate),
);

const formats = ['d.L.yy', 'd.L.y', 'd/L/yy', 'd/L/y', 'd-L-yy', 'd-L-y'];

const dateIsValid = date => Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date.getTime());

const parseDateText = (text) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const format of formats) {
    const d = dtParse(text, format, new Date());
    if (dateIsValid(d)) return d;
  }
  return false;
};

const useStyles = makeStyles({
  calendarContainer: {
    margin: '1rem',
  },
  calendar: {
    display: 'inline-block',
  },
  calendarHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '1rem',
  },
  dateTextInput: {
    flexGrow: '1',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
});

const Calendar = ({
  label,
  value,
  onChange,
  fieldName,
  calendarProps,
  onEnterKeyPress,
  locale,
}) => {
  const classes = useStyles();
  const [textInput, setTextInput] = useState('');
  const [inputValid, setInputValid] = useState(true);

  function usePrevious(val) {
    const ref = useRef();
    useEffect(() => {
      ref.current = val;
    });
    return ref.current;
  }

  const prevTextInput = usePrevious(textInput);

  useEffect(() => {
    const newText = dtFormat(value, 'P', { locale });

    if (prevTextInput === textInput && textInput !== newText) {
      // eslint-disable-next-line react/no-did-update-set-state
      setTextInput(newText);
    }
  }, [textInput, locale, prevTextInput, value]);

  return (
    <div className={classes.calendarContainer} tabIndex="-1">
      <div className={classes.calendarHeader}>
        <InputLabel>{label}</InputLabel>
        <TextField
          className={classes.dateTextInput}
          error={!inputValid}
          margin="dense"
          value={textInput}
          onChange={(e) => {
            const input = e.target.value;
            const parsed = parseDateText(input);
            let validInput = false;
            if (
              parsed
                && !shouldDisableDate(
                  parsed,
                  calendarProps.disablePast,
                  calendarProps.disableFuture,
                  calendarProps.minDate,
                  calendarProps.maxDate,
                )
            ) {
              onChange(fieldName, parsed);
              validInput = true;
            }

            setTextInput(input);
            setInputValid(validInput);
          }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              if (onEnterKeyPress) onEnterKeyPress(ev);
              ev.preventDefault();
            }
          }}
        />
        <IconButton
          onClick={() => {
            onChange(fieldName, new Date());
            setTextInput(dtFormat(new Date(), 'P', { locale }));
          }}
        >
          <TodayIcon />
        </IconButton>
      </div>
      <Paper
        tabIndex="-1"
        elevation={0}
        className={classes.calPaper}
        style={{ overflow: 'hidden' }}
      >
        <MuiCalendar
          allowKeyboardControl={false}
          className={classes.calendar}
          date={value}
          renderDay={(day, selectedDate, dayInCurrentMonth) => (
            <Day
              disabled={shouldDisableDate(
                day,
                calendarProps.disablePast,
                calendarProps.disableFuture,
                calendarProps.minDate,
                calendarProps.maxDate,
              )}
              current={isSameDay(day, new Date())}
              hidden={!dayInCurrentMonth}
              selected={isSameDay(selectedDate, day)}
            >
              {getDate(day)}
            </Day>
          )}
          tabIndex={-1}
          onChange={(d) => {
            onChange(fieldName, d);
            setTextInput(dtFormat(d, 'P', { locale }));
          }}
          {...calendarProps}
        />
      </Paper>
    </div>
  );
};

Calendar.propTypes = {
  label: PropTypes.any.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  calendarProps: PropTypes.object,
  onEnterKeyPress: PropTypes.func,
  locale: PropTypes.object,
};

Calendar.defaultProps = {
  calendarProps: {},
  onEnterKeyPress: undefined,
  locale: {},
};

export default Calendar;
