import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { localizeReducer as localize } from 'react-localize-redux';

import { createFetchReducer } from '@trdk/style/Redux';

import { reducer as gasData } from './components/gas';
import { reducer as dustData } from './components/dust';

const selectedSensorReducer = (state = null, action) => {
  if (action.type === 'SET_SELECTED_SENSOR') { return action.value; }
  return state;
};

const rootReducer = combineReducers({
  localize,
  sensors: createFetchReducer('SENSORS', undefined, []),
  sensorNILUDustData: createFetchReducer('SENSOR_NILU_DUST_DATA', undefined, null),
  sensorWeatherData: createFetchReducer('SENSOR_WEATHER_DATA', undefined, null),
  selectedSensor: selectedSensorReducer,
  gasData,
  dustData,
});

// enable redux dev-tools
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
