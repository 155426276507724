import L from 'leaflet';

const [greenMarkerUrl, orangeMarkerUrl, redMarkerUrl, markerShadowUrl] = [
  'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
  'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
];

export const greenMarker = new L.Icon({
  iconUrl: greenMarkerUrl,
  shadowUrl: markerShadowUrl,
  iconSize: [22, 36],
  iconAnchor: [9, 36],
  popupAnchor: [1, -34],
  shadowSize: [36, 36],
});

export const greenMarkerBig = new L.Icon({
  iconUrl: greenMarkerUrl,
  shadowUrl: markerShadowUrl,
  iconSize: [36, 59],
  iconAnchor: [17, 59],
  popupAnchor: [1, -34],
  shadowSize: [59, 59],
});

export const orangeMarker = new L.Icon({
  iconUrl: orangeMarkerUrl,
  shadowUrl: markerShadowUrl,
  iconSize: [22, 36],
  iconAnchor: [9, 36],
  popupAnchor: [1, -34],
  shadowSize: [36, 36],
});

export const orangeMarkerBig = new L.Icon({
  iconUrl: orangeMarkerUrl,
  shadowUrl: markerShadowUrl,
  iconSize: [36, 59],
  iconAnchor: [17, 59],
  popupAnchor: [1, -34],
  shadowSize: [59, 59],
});

export const redMarker = new L.Icon({
  iconUrl: redMarkerUrl,
  shadowUrl: markerShadowUrl,
  iconSize: [22, 36],
  iconAnchor: [9, 36],
  popupAnchor: [1, -34],
  shadowSize: [36, 36],
});

export const redMarkerBig = new L.Icon({
  iconUrl: redMarkerUrl,
  shadowUrl: markerShadowUrl,
  iconSize: [36, 59],
  iconAnchor: [17, 59],
  popupAnchor: [1, -34],
  shadowSize: [59, 59],
});
