import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  current: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  selected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  disabled: {
    pointerEvents: 'none',
    color: theme.palette.text.hint,
  },
}));

const Day = ({
  children, disabled, hidden, current, selected, ...other
}) => {
  const classes = useStyles();
  const className = clsx(classes.day, {
    [classes.hidden]: hidden,
    [classes.current]: current,
    [classes.selected]: selected,
    [classes.disabled]: disabled,
  });

  return (
    <IconButton className={className} tabIndex={-1} {...other}>
      {children}
    </IconButton>
  );
};

Day.propTypes = {
  children: PropTypes.node.isRequired,
  current: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  selected: PropTypes.bool,
};

Day.defaultProps = {
  disabled: false,
  hidden: false,
  current: false,
  selected: false,
};

export default Day;
