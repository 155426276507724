import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GlobalTranslations from './global.translations.json';

let language = (window.navigator.userLanguage || window.navigator.language).substring(0, 2);
if (language !== 'nb' && language !== 'en') {
  language = 'nb';
}
const lang = localStorage.getItem('language') || language;

document.documentElement.lang = lang;

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider store={store}
                      initialize={{
                        languages: [
                          { name: 'Norsk bokmål', code: 'nb' },
                          { name: 'English', code: 'en' },
                        ],
                        translation: GlobalTranslations,
                        options: {
                          defaultLanguage: lang,
                          renderToStaticMarkup,
                        },
                      }}>
      <App />
    </LocalizeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
