/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { sub } from 'date-fns';
import {
  Hidden, Box, Typography, IconButton, FormControlLabel, Checkbox,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { doFetch } from '@trdk/style/Redux';
import { fetchClear } from '@trdk/style/Redux/fetch.actions';
import GraphExpanderButton from '../graph-expander-button';
import DateRangePicker from '../DateRangePicker';
import DustGraph from './dust-graph';
import MultiToggleButton from '../multi-toggle-button';
import DustInfo from './dust-info';
import NILUDialog from './NILU-info';

import { getSensorDustData } from './dustData.actions';
import { getNILUDustData } from '../../services/sensor-service';

const toggleButtonContents = [
  { value: 'day', shortId: 'time.last24HoursShort', longId: 'time.last24Hours' },
  { value: 'week', shortId: 'time.lastWeekShort', longId: 'time.lastWeek' },
  { value: 'month', shortId: 'time.lastMonthShort', longId: 'time.lastMonth' },
  { value: 'custom', shortId: 'time.custom', longId: 'time.custom' },
];

const intervalButtonContents = [
  { value: 'hour', shortId: 'time.hour', longId: 'time.hour' },
  { value: 'day', shortId: 'time.day', longId: 'time.day' },
];

export default function Dust({ commonGraphClasses }) {
  const [customFromDate, setCustomFromDate] = useState(new Date());
  const [customToDate, setCustomToDate] = useState(new Date());
  const [hasDateRangeSubmitBeenPressed, setHasDateRangeSubmitBeenPressed] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState('week');
  const [dataPointSize, setDataPointSize] = useState('day');
  const [isGraphExpanded, setIsGraphExpanded] = useState(false);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [isNILUPopupOpen, setIsNILUPopupOpen] = useState(false);
  const [isComparingWithNILU, setIsComparingWithNILU] = useState(false);
  const [allowNILUComparison, setAllowNILUComparison] = useState(false);

  const { isFetchingData, fetchError } = useSelector(state => state.dustData);
  const selectedSensor = useSelector(state => state.selectedSensor);
  const dispatch = useDispatch();

  async function fetchData(wasDateRangeSubmitPressed = false, isNILUData) {
    const isCustomInterval = selectedInterval === 'custom';
    const fromDate = isCustomInterval ? customFromDate : intervalToStartDate(selectedInterval);
    const toDate = isCustomInterval ? customToDate : new Date();

    const wasCustomMultiButtonPressed = isCustomInterval
      && !hasDateRangeSubmitBeenPressed
      && !wasDateRangeSubmitPressed;

    if (wasCustomMultiButtonPressed) { return; }

    if (isNILUData) {
      dispatch(doFetch('SENSOR_NILU_DUST_DATA', getNILUDustData(
        selectedSensor.deviceID,
        fromDate,
        toDate,
        dataPointSize,
      )));
    } else {
      dispatch(getSensorDustData(
        selectedSensor.deviceID,
        fromDate,
        toDate,
        selectedInterval,
        dataPointSize,
      ));
    }
  }

  useEffect(() => {
    if (selectedSensor && !isFetchingData && !fetchError) {
      fetchData();
    }
  }, [selectedSensor, selectedInterval, dataPointSize]);

  useEffect(() => {
    const shouldAllowComparison = selectedSensor && (
      selectedSensor.deviceID === '2f3a11687f7a2j' || selectedSensor.deviceID === '17dh0cf43jg89l');

    if (!shouldAllowComparison) {
      setIsComparingWithNILU(false);
    }
    setAllowNILUComparison(shouldAllowComparison);
  }, [selectedSensor]);

  useEffect(() => {
    if (selectedSensor && isComparingWithNILU && allowNILUComparison) {
      fetchData(false, true);
    } else if (selectedSensor && !isComparingWithNILU) {
      dispatch(fetchClear('SENSOR_NILU_DUST_DATA'));
    }
  }, [isComparingWithNILU, selectedSensor, selectedInterval, dataPointSize]);

  function onIntervalChanged(selectedValue) {
    setSelectedInterval(selectedValue);
    if (selectedValue !== 'custom') {
      setCustomFromDate(new Date());
      setCustomToDate(new Date());
      setHasDateRangeSubmitBeenPressed(false);
    }
  }

  function onDataPointSizeChanged(newSize) {
    setDataPointSize(newSize);
  }

  function onCustomDateIntervalChanged(dateType, dateValue) {
    if (dateType === 'from') {
      setCustomFromDate(dateValue);
    } else {
      setCustomToDate(dateValue);
    }
  }

  function onCustomDateIntervalSubmit() {
    setHasDateRangeSubmitBeenPressed(true);
    fetchData(true, false);
    if (isComparingWithNILU) {
      fetchData(false, true);
    }
  }

  function intervalToStartDate(interval) {
    switch (interval) {
      case 'day':
        return sub(new Date(), { hours: 24 });
      case 'week':
        return sub(new Date(), { days: 7 });
      case 'month':
        return sub(new Date(), { months: 1 });
      default:
        return new Date();
    }
  }

  return (
    <Box margin="0.75rem 0">
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        padding="0 0.5rem"
        className={clsx(commonGraphClasses.dateButtonsContainer,
            commonGraphClasses.rightSideResponsivePadding)}
      >

        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
          <Typography
            variant="h4"
            className={clsx(
                        commonGraphClasses.alignStart, commonGraphClasses.graphTitle,
                      )}
          >
            <Translate id="domain.dust" />
          </Typography>

          <IconButton
            onClick={() => setIsInfoPopupOpen(true)}
            className={clsx(
                        commonGraphClasses.graphInfoIcon, commonGraphClasses.alignStart,
                      )}
            size="small"
          >
            <InfoIcon fontSize="small" />
          </IconButton>

          <DustInfo open={isInfoPopupOpen} onClose={() => setIsInfoPopupOpen(false)} />
          <NILUDialog open={isNILUPopupOpen} onClose={() => setIsNILUPopupOpen(false)} />

          <Hidden smDown>
            <MultiToggleButton
              onChange={onIntervalChanged}
              buttons={toggleButtonContents}
              defaultInterval="week"
              className={clsx(commonGraphClasses.mb025, commonGraphClasses.mlr1)}
              isDisabled={isFetchingData}
            />

            <MultiToggleButton
              onChange={onDataPointSizeChanged}
              buttons={intervalButtonContents}
              defaultInterval="day"
              className={clsx(commonGraphClasses.mb025, commonGraphClasses.mr1)}
              isDisabled={isFetchingData}
            />

            {allowNILUComparison && (
              <Box display="flex" flexDirection="row" flexWrap="nowrap" marginRight="0.5rem" alignSelf="flex-start">
                <FormControlLabel
                  label="NILU-data"
                  style={{ marginRight: 0 }}
                  control={(
                    <Checkbox
                      checked={isComparingWithNILU}
                      onChange={e => setIsComparingWithNILU(e.target.checked)}
                      name="checkedB"
                      color="primary"
                      style={{ marginRight: '-0.5rem', paddingTop: '0', paddingBottom: '0' }}
                    />
                  )}
                />

                <IconButton
                  onClick={() => setIsNILUPopupOpen(true)}
                  className={commonGraphClasses.graphInfoIcon}
                  size="small"
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Hidden>

        </Box>

        {!fetchError && (
          <GraphExpanderButton
            isExpanded={isGraphExpanded}
            onButtonClick={setIsGraphExpanded}
            smallerText={<Translate id="main.smallerGraph" />}
            biggerText={<Translate id="main.biggerGraph" />}
            isDisabled={isFetchingData}
          />
        )}
      </Box>

      <Hidden mdUp>
        <Box padding="0 1rem" display="flex" flexDirection="row" flexWrap="wrap">
          <MultiToggleButton
            onChange={onIntervalChanged}
            buttons={toggleButtonContents}
            defaultInterval="week"
            className={commonGraphClasses.mr1}
            isDisabled={isFetchingData}
          />

          <MultiToggleButton
            onChange={onDataPointSizeChanged}
            buttons={intervalButtonContents}
            defaultInterval="day"
            isDisabled={isFetchingData}
            className={commonGraphClasses.mr1}
          />

          {allowNILUComparison && (
            <Box display="flex" flexDirection="row" flexWrap="nowrap" marginRight="0.5rem">
              <FormControlLabel
                label="NILU-data"
                style={{ marginRight: 0 }}
                control={(
                  <Checkbox
                    checked={isComparingWithNILU}
                    onChange={e => setIsComparingWithNILU(e.target.checked)}
                    name="checkedB"
                    color="primary"
                    style={{ marginRight: '-0.5rem', paddingTop: '0', paddingBottom: '0' }}
                  />
                )}
              />


              <IconButton
                onClick={() => setIsNILUPopupOpen(true)}
                className={commonGraphClasses.graphInfoIcon}
                size="small"
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      </Hidden>

      {selectedInterval === 'custom' && (
        <DateRangePicker
          from={customFromDate}
          to={customToDate}
          locale="nb"
          fromProps={{ disableFuture: true, maxDate: customToDate }}
          toProps={{ disableFuture: true, minDate: customFromDate }}
          submitLabel={<Translate id="main.confirm" />}
          onChange={onCustomDateIntervalChanged}
          onSearch={onCustomDateIntervalSubmit}
          className={commonGraphClasses.dateRangePicker}
          fromLabel={<Translate id="main.from" />}
          toLabel={<Translate id="main.to" />}
        />
      )}

      <DustGraph
        loadDataFunction={fetchData}
        isExpanded={isGraphExpanded}
        shouldDisplayHours={dataPointSize === 'hour'}
        dataPointSize={dataPointSize}
        commonGraphClasses={commonGraphClasses}
      />
    </Box>
  );
}

Dust.propTypes = {
  commonGraphClasses: PropTypes.objectOf(PropTypes.string).isRequired,
};
