const urlPrefix = '/api';

export default async function getWeatherData(sensorId, startDate, endDate, dataPointSize) {
  const startTime = startDate.getTime();
  const endTime = endDate.getTime();

  const fullUrl = `${urlPrefix}/v1/sensors/${sensorId}/weather?from=${startTime}&to=${endTime}&dataPointSize=${dataPointSize}`;
  const response = await fetch(fullUrl);
  const data = await response.json();

  data.data = data.data.map(datapoint => ({
    ...datapoint,
    precipitation: datapoint.precipitation === -1 ? null : datapoint.precipitation,
  }));
  data.distanceKm = roundToDecimals(data.distanceKm, 1);

  return data;
}


function roundToDecimals(number, decimals) {
  return (Math.round((10 ** decimals) * number)) / (10 ** decimals);
}
