import { differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';

export function processWeatherData(data) {
  const processedData = {
    data: [],
  };

  data.forEach((d) => {
    processedData.data.push({
      time: d.timestamp,
      temperature: d.temperature,
      humidity: d.humidity,
    });
  });

  return processedData;
}

export function processGasData(data) {
  const processedData = {
    data: [],
  };

  data.forEach((d) => {
    processedData.data.push({
      time: d.timestamp,
      NO: d.NO,
      NO2: d.NO2,
      O3: d.O3,
    });
  });

  return processedData;
}

export function processDustData(data) {
  const processedData = {
    data: [],
  };

  data.forEach((d) => {
    processedData.data.push({
      time: d.timestamp,
      displayedPM10: d.PM10 - d['PM2.5'],
      PM10: d.PM10,
      'PM2.5': d['PM2.5'],
    });
  });

  return processedData;
}

export function processFrostData(data) {
  const outputData = [];
  data.forEach((d) => {
    outputData.push({
      timestamp: (new Date(d.referenceTime)).getTime(),
      temperature: d.observations.find(o => o.elementId === 'air_temperature').value,
      humidity: d.observations.find(o => o.elementId === 'relative_humidity').value,
      rain: d.observations.find(o => o.elementId === 'sum(precipitation_amount PT1H)').value,
    });
  });
}

export function processNILUDustData(data) {
  const pm10Data = data.find(d => d.component === 'PM10').values;
  const pm25Data = data.find(d => d.component === 'PM2.5').values;
  const outputData = [];

  for (let i = 0; i < pm10Data.length; i += 1) {
    const pm10 = Math.max(0, Math.round(10 * pm10Data[i].value) / 10);

    let pm25 = 0;
    const pm25DPIndex = pm25Data.findIndex(dp => dp.dateTime === pm10Data[i].dateTime);
    if (pm25DPIndex > -1) {
      const pm25Raw = pm25Data.splice(pm25DPIndex, 1)[0];
      pm25 = Math.max(0, Math.round(10 * pm25Raw.value) / 10);
    }

    let timestamp;
    if ('dateTime' in pm10Data[i]) {
      timestamp = (new Date(pm10Data[i].dateTime).getTime());
    }
    else {
      timestamp = (new Date(pm10Data[i].toTime).getTime());
    }

    outputData.push({
      time: timestamp,
      PM10: pm10,
      displayedPM10: pm10 - pm25,
      PM25: pm25,
    });
  }

  return outputData;
}

export function calculateLastReceivedStatus(lastTimestamp) {
  const lastReceivedDate = new Date(lastTimestamp);
  const now = new Date();

  let timeAgoNumber;
  let timeIntervalTranslateId;
  let status;
  let statusTextTranslateId;

  const lastReceivedMinutes = differenceInMinutes(now, lastReceivedDate);
  const lastReceivedHours = differenceInHours(now, lastReceivedDate);
  const lastReceivedDays = differenceInDays(now, lastReceivedDate) + 1;

  if (lastReceivedMinutes < 5) {
    timeAgoNumber = lastReceivedMinutes;
    timeIntervalTranslateId = 'time.minute';
    status = sensorStatuses.healthy;
    statusTextTranslateId = 'main.healthy';
  }
  else if (lastReceivedMinutes < 60) {
    timeAgoNumber = lastReceivedMinutes;
    timeIntervalTranslateId = 'time.minute';
    status = sensorStatuses.unstable;
    statusTextTranslateId = 'main.unstable';
  }
  else if (lastReceivedHours <= 48) {
    timeAgoNumber = lastReceivedHours;
    timeIntervalTranslateId = lastReceivedHours === 1 ? 'time.hour' : 'time.hours';
    status = sensorStatuses.unstable;
    statusTextTranslateId = 'main.unstable';
  }
  else {
    timeAgoNumber = lastReceivedDays;
    timeIntervalTranslateId = lastReceivedDays === 1 ? 'time.day' : 'time.days';
    status = sensorStatuses.offline;
    statusTextTranslateId = 'main.offline';
  }

  return {
    timeAgoNumber, timeIntervalTranslateId, status, statusTextTranslateId,
  };
}

export const sensorStatuses = {
  healthy: 'healthy',
  unstable: 'unstable',
  offline: 'offline',
};
