/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { sub, startOfDay } from 'date-fns';
import {
  Hidden, Box, Typography, IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { doFetch } from '@trdk/style/Redux';
import { useSelector, useDispatch } from 'react-redux';
import DateRangePicker from '../DateRangePicker';
import WeatherGraph from './weather-graph';
import TempHumidityInfo from './weather-info';
import DateMultiToggleButton from '../multi-toggle-button';
import getWeatherData from './weather-service';

const fiveDaysMillisec = 86400 * 1000 * 5;

const toggleButtonContents = [
  { value: 'day', shortId: 'time.last24HoursShort', longId: 'time.last24Hours' },
  { value: 'week', shortId: 'time.lastWeekShort', longId: 'time.lastWeek' },
  { value: 'month', shortId: 'time.lastMonthShort', longId: 'time.lastMonth' },
  { value: 'custom', shortId: 'time.custom', longId: 'time.custom' },
];

export default function Weather({ commonGraphClasses }) {
  const [customFromDate, setCustomFromDate] = useState(new Date());
  const [customToDate, setCustomToDate] = useState(new Date());
  const [hasDateRangeSubmitBeenPressed, setHasDateRangeSubmitBeenPressed] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState('week');
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);

  const {
    fetching: isFetchingData,
  } = useSelector(state => state.sensorWeatherData);
  const {
    fetched: hasFetchedWeatherData,
    payload: weatherData,
  } = useSelector(state => state.sensorWeatherData);

  const selectedSensor = useSelector(state => state.selectedSensor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSensor) {
      fetchData();
    }
  }, [selectedSensor, selectedInterval]);

  const onIntervalChanged = useCallback((selectedValue) => {
    setSelectedInterval(selectedValue);
    if (selectedValue !== 'custom') {
      setCustomFromDate(new Date());
      setCustomToDate(new Date());
      setHasDateRangeSubmitBeenPressed(false);
    }
  }, []);

  function onCustomDateIntervalChanged(dateType, dateValue) {
    if (dateType === 'from') {
      setCustomFromDate(dateValue);
    }
    else {
      setCustomToDate(dateValue);
    }
  }

  function onCustomDateIntervalSubmit() {
    fetchData(true);
    setHasDateRangeSubmitBeenPressed(true);
  }

  async function fetchData(wasDateRangeSubmitPressed = false) {
    const isCustomInterval = selectedInterval === 'custom';
    const fromDate = isCustomInterval ? customFromDate : intervalToStartDate(selectedInterval);
    const toDate = isCustomInterval ? customToDate : new Date();

    const wasCustomMultiButtonPressed = isCustomInterval
      && !hasDateRangeSubmitBeenPressed
      && !wasDateRangeSubmitPressed;

    if (wasCustomMultiButtonPressed) { return; }

    const dataPointSize = (toDate - fromDate > fiveDaysMillisec) ? 'day' : 'hour';

    dispatch(doFetch('SENSOR_WEATHER_DATA', getWeatherData(selectedSensor.deviceID, fromDate, toDate, dataPointSize)));
  }

  function intervalToStartDate(interval) {
    switch (interval) {
      case 'day':
        return sub(new Date(), { hours: 24 });
      case 'week':
        return startOfDay(sub(new Date(), { days: 7 }));
      case 'month':
        return startOfDay(sub(new Date(), { months: 1 }));
      default:
        return new Date();
    }
  }

  return (
    <Box margin="0.75rem 0">
      <Box display="flex"
           flexDirection="row"
           flexWrap="wrap"
           alignItems="center"
           padding="0 0.5rem"
           className={clsx(commonGraphClasses.dateButtonsContainer,
            commonGraphClasses.rightSideResponsivePadding)}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h4"
                      className={clsx(
                        commonGraphClasses.alignStart, commonGraphClasses.graphTitle,
                      )}>
            <Translate id="domain.weather" />
            {hasFetchedWeatherData && weatherData && weatherData.sensorName && (
              ` - sensor: ${weatherData.sensorName}`
            )}
          </Typography>

          <IconButton onClick={() => setIsInfoPopupOpen(true)}
                      className={
                        clsx(commonGraphClasses.graphInfoIcon, commonGraphClasses.alignStart)
                      }
                      size="small">
            <InfoIcon fontSize="small" />
          </IconButton>

          <TempHumidityInfo open={isInfoPopupOpen} onClose={() => setIsInfoPopupOpen(false)} />

          <Hidden smDown>
            <DateMultiToggleButton onChange={onIntervalChanged}
                                   buttons={toggleButtonContents}
                                   className={clsx(
                                     commonGraphClasses.mb025, commonGraphClasses.mlr1,
                                    )}
                                   defaultInterval="week"
                                   isDisabled={isFetchingData} />
          </Hidden>
        </Box>
      </Box>

      <Box display="flex"
           flexDirection="row"
           flexWrap="wrap"
           alignItems="center"
           margin="0.5rem 0"
           className={commonGraphClasses.rightSideResponsivePadding}>
        <Hidden mdUp>
          <DateMultiToggleButton onChange={onIntervalChanged}
                                 buttons={toggleButtonContents}
                                 defaultInterval="day"
                                 isDisabled={isFetchingData} />
        </Hidden>

        {selectedInterval === 'custom' && (
          <DateRangePicker from={customFromDate}
                           to={customToDate}
                           locale="nb"
                           submitLabel={<Translate id="main.confirm" />}
                           onChange={onCustomDateIntervalChanged}
                           onSearch={onCustomDateIntervalSubmit}
                           fromLabel={<Translate id="main.from" />}
                           toLabel={<Translate id="main.to" />} />
        )}
      </Box>

      <WeatherGraph loadDataFunction={fetchData}
                    currentInterval={selectedInterval}
                    commonGraphClasses={commonGraphClasses} />
    </Box>
  );
}

Weather.propTypes = {
  commonGraphClasses: PropTypes.objectOf(PropTypes.string).isRequired,
};
