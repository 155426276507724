/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { sub } from 'date-fns';
import {
  Hidden, Box, Typography, IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import GraphExpanderButton from '../graph-expander-button';
import DateRangePicker from '../DateRangePicker';
import GasGraph from './gas-graph';
import GasesInfo from './gases-info';
import MultiToggleButton from '../multi-toggle-button';

import { getSensorGasData } from './gasData.actions';

export default function Gases({ commonGraphClasses }) {
  const [customFromDate, setCustomFromDate] = useState(new Date());
  const [customToDate, setCustomToDate] = useState(new Date());
  const [hasDateRangeSubmitBeenPressed, setHasDateRangeSubmitBeenPressed] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState('day');
  const [dataPointSize, setDataPointSize] = useState('hour');
  const [isGraphExpanded, setIsGraphExpanded] = useState(false);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);

  const { isFetchingData, fetchError } = useSelector(state => state.gasData);
  const selectedSensor = useSelector(state => state.selectedSensor);
  const dispatch = useDispatch();

  const fetchData = async (wasDateRangeSubmitPressed = false) => {
    const isCustomInterval = selectedInterval === 'custom';
    const fromDate = isCustomInterval ? customFromDate : intervalToStartDate(selectedInterval);
    const toDate = isCustomInterval ? customToDate : new Date();
    const wasCustomMultiButtonPressed = isCustomInterval
      && !hasDateRangeSubmitBeenPressed
      && !wasDateRangeSubmitPressed;

    if (wasCustomMultiButtonPressed) { return; }

    dispatch(getSensorGasData(
      selectedSensor.deviceID,
      fromDate,
      toDate,
      selectedInterval,
      dataPointSize,
    ));
  };

  useEffect(() => {
    if (selectedSensor && !isFetchingData && !fetchError) {
      fetchData();
    }
  }, [selectedSensor, selectedInterval, dataPointSize]);

  const onIntervalChanged = (selectedValue) => {
    setSelectedInterval(selectedValue);
    if (selectedValue !== 'custom') {
      setCustomFromDate(new Date());
      setCustomToDate(new Date());
      setHasDateRangeSubmitBeenPressed(false);
    }
  };

  const onDataPointSizeChanged = (newSize) => {
    setDataPointSize(newSize);
  };

  function onCustomDateIntervalChanged(dateType, dateValue) {
    if (dateType === 'from') {
      setCustomFromDate(dateValue);
    }
    else {
      setCustomToDate(dateValue);
    }
  }

  function onCustomDateIntervalSubmit() {
    fetchData(true);
    setHasDateRangeSubmitBeenPressed(true);
  }

  return (
    <Box margin="0.75rem 0">
      <Box display="flex"
           flexDirection="row"
           flexWrap="nowrap"
           alignItems="center"
           padding="0 0.5rem"
           className={clsx(commonGraphClasses.dateButtonsContainer, commonGraphClasses.rightSideResponsivePadding)}>

        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
          <Typography variant="h4" className={clsx(commonGraphClasses.alignStart, commonGraphClasses.graphTitle)}>
            <Translate id="domain.gases" />
          </Typography>

          <IconButton onClick={() => setIsInfoPopupOpen(true)}
                      className={clsx(commonGraphClasses.graphInfoIcon, commonGraphClasses.alignStart)}
                      size="small">
            <InfoIcon fontSize="small" />
          </IconButton>

          <GasesInfo open={isInfoPopupOpen} onClose={() => setIsInfoPopupOpen(false)} />

          <Hidden smDown>
            <MultiToggleButton onChange={onIntervalChanged}
                               buttons={toggleButtonContents}
                               defaultInterval="day"
                               className={clsx(commonGraphClasses.mb025, commonGraphClasses.mlr1)}
                               isDisabled={isFetchingData} />

            <MultiToggleButton onChange={onDataPointSizeChanged}
                               buttons={intervalButtonContents}
                               defaultInterval="hour"
                               className={clsx(commonGraphClasses.mb025, commonGraphClasses.mr1)}
                               isDisabled={isFetchingData} />
          </Hidden>
        </Box>

        {!fetchError && (
          <GraphExpanderButton isExpanded={isGraphExpanded}
                               onButtonClick={setIsGraphExpanded}
                               smallerText={<Translate id="main.smallerGraph" />}
                               biggerText={<Translate id="main.biggerGraph" />}
                               isDisabled={isFetchingData} />
        )}
      </Box>

      <Hidden mdUp>
        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" className={commonGraphClasses.rightSideResponsivePadding}>
          <MultiToggleButton onChange={onIntervalChanged}
                             buttons={toggleButtonContents}
                             defaultInterval="day"
                             className={commonGraphClasses.mr1}
                             isDisabled={isFetchingData} />

          <MultiToggleButton onChange={onDataPointSizeChanged}
                             buttons={intervalButtonContents}
                             defaultInterval="hour"
                             isDisabled={isFetchingData} />
        </Box>
      </Hidden>

      {selectedInterval === 'custom' && (
        <DateRangePicker from={customFromDate}
                         to={customToDate}
                         locale="nb"
                         submitLabel={<Translate id="main.confirm" />}
                         onChange={onCustomDateIntervalChanged}
                         onSearch={onCustomDateIntervalSubmit}
                         className={commonGraphClasses.dateRangePicker}
                         fromLabel={<Translate id="main.from" />}
                         toLabel={<Translate id="main.to" />} />
          )}

      <GasGraph loadDataFunction={fetchData}
                isExpanded={isGraphExpanded}
                currentInterval={selectedInterval}
                dataPointSize={dataPointSize}
                shouldDisplayHours={dataPointSize !== 'day'}
                commonGraphClasses={commonGraphClasses} />
    </Box>
  );
}

const toggleButtonContents = [
  { value: 'day', shortId: 'time.last24HoursShort', longId: 'time.last24Hours' },
  { value: 'week', shortId: 'time.lastWeekShort', longId: 'time.lastWeek' },
  { value: 'month', shortId: 'time.lastMonthShort', longId: 'time.lastMonth' },
  { value: 'custom', shortId: 'time.custom', longId: 'time.custom' },
];

const intervalButtonContents = [
  { value: 'hour', shortId: 'time.hour', longId: 'time.hour' },
  { value: 'day', shortId: 'time.day', longId: 'time.day' },
];


function intervalToStartDate(interval) {
  switch (interval) {
    case 'hours':
      return sub(new Date(), { hours: 4 });
    case 'day':
      return sub(new Date(), { hours: 24 });
    case 'week':
      return sub(new Date(), { days: 7 });
    case 'month':
      return sub(new Date(), { months: 1 });
    default:
      return new Date();
  }
}

Gases.propTypes = {
  commonGraphClasses: PropTypes.objectOf(PropTypes.string),
};
